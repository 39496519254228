<template>
  <div>
    <div class="pt-md-0 pt-2 no-print"
         v-if="visit && object">
      <b-tabs nav-class="sub-menu"
              content-class="pt-2"
              @activate-tab="tabChanged">
        <b-tab :active="page === 'description'"
               :title="$t('description')">
          <PatientVisitDescription :object="object"
                                   ref="descriptionTab"
                                   :services="services"
                                   :diagnoses="diagnoses"
                                   :key="`visit-description-counter-${counter}`"
                                   :materials="materials"
                                   @applyComplex="applyComplex"
                                   @reloadVisit="reloadVisit"
                                   @reload="teethReload"
                                   :packages="packages"/>
        </b-tab>
        <b-tab :active="page === 'teeth'"
               :title="$t('teeth')">
          <PatientVisitTeeth :object="object"
                             ref="teethTab"
                             :key="`visit-teeth-counter-${counter}`"
                             @reloadVisit="reloadVisit"
                             @applyComplex="applyComplex"
                             @parseTeethDescriptions="parseTeethDescriptions"/>
        </b-tab>
        <b-tab :active="page === 'xray'"
               :title="$t('xray')"
               lazy>
          <PatientVisitXray/>
        </b-tab>
        <b-tab :active="page === 'egisz'"
                v-if="menu.find(m => m.title == 'egisz')"
               :title="$t('egisz')"
               lazy>
          <PatientVisitEGISZ @reloadVisit="reloadVisit"/>
        </b-tab>
        <b-tab :active="page === 'other'"
               :title="$t('other')"
               lazy>
          <PatientVisitOther/>
        </b-tab>
      </b-tabs>
    </div>
    <PlanTreatmentOptionsModal :complex="planComplex"
                               @reload="teethReload"/>
  </div>
</template>

<script>

import { mapActions, mapState } from "vuex"
import PatientVisitDescription from "@/components/pages/patients/visit/PatientVisitDescription"
import PatientVisitTeeth from "@/components/pages/patients/visit/PatientVisitTeeth"
import PatientVisitXray from "@/components/pages/patients/visit/PatientVisitXray"
import PatientVisitEGISZ from "@/components/pages/patients/visit/PatientVisitEGISZ"
import PatientVisitOther from "@/components/pages/patients/visit/PatientVisitOther"
import PlanTreatmentOptionsModal from "../../parts/patients/PlanTreatmentOptionsModal"
import { EloquentService } from "@/services/api.service"

export default {
  name: "PatientVisit",
  components: {
    PlanTreatmentOptionsModal,
    PatientVisitXray,
    PatientVisitTeeth,
    PatientVisitDescription,
    PatientVisitEGISZ,
    PatientVisitOther
  },
  head() {
    return {
      title: this.$t("visit"),
    }
  },
  data() {
    return {
      planComplex: null,
      object: null
    }
  },
  computed: {
    ...mapState({
      visit:  state => state.patient.visit,
      diagnoses: state => state.treatment.diagnoses,
      services: state => state.treatment.services,
      packages: state => state.treatment.packages,
      materials: state => state.treatment.materials,
      branch: state => state.auth.branch,
      rights: state => state.auth.rights,
      counter: state => state.datatable.dataTableCounter
    }),
    page() {
      return this.$route.params.page
    },
    isPatient() {
      return this.$store.getters.isPatient
    },
    menu() {
      const arr = [
        { title: 'description', new: true },
        { title: 'teeth', new: false },
        { title: 'xray', new: false },
      ]
      if(this.rights.includes('egisz_edit')) {
        arr.push({ title: 'egisz', new: false })
      }
      arr.push({ title: 'other', new: false })
      return arr
    }
  },
  methods: {
    ...mapActions(['getClientVisit']),
    parseTeethDescriptions(teethDescriptions) {
      this.teethDescriptions = teethDescriptions
    },
    tabChanged(tab) {
      this.$router.push({ name: 'PatientVisitPage', params: {
          id: this.$route.params.id,
          visitid: this.$route.params.visitid,
          page: this.menu[tab].title
        }
      }).catch(() => {})
    },
    async applyComplex(complex_id) {
      if(!complex_id) return
      this.planComplex = (await EloquentService.show('plan_visit', complex_id)).data
      await this.$nextTick()
      this.$bvModal.show('plan-treatment-options-modal')
    },
    async intermediateSave() {
      await this.$refs.descriptionTab.save(false)
    },
    teethReload() {
      this.$refs.teethTab.forceUpdate()
    },
    reloadVisit() {
      this.getClientVisit({
        id: this.$route.params.id,
        visit_id: this.$route.params.visitid
      }).then(() => {
        if(this.visit?.isOtherBranch) {
          this.$router.push('/').catch(()=>{})
          return
        }
        this.object = this.copyObject(this.visit)
        this.$store.commit('incrementDataTableCounter')
      })
    }
  },
  mounted() {
    if(this.isPatient) {
      this.$router.push('/').catch(() => {})
      return
    }
    if(this.$route.params.visitid === 'new') {
      this.$store.commit('setVisit', { id: 'new', 'client_id': this.$route.params.id })
      return
    }
    this.reloadVisit()
  },
  beforeUnmount() {
    this.$store.commit('setVisit', null)
    this.$store.commit('nullifyTreatmentCard')
  },
  watch: {
    counter() {
      this.object = this.copyObject(this.visit)
    }
  }
}
</script>
