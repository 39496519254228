<template>
  <b-modal id="plan-treatment-modal"
           size="xl"
           no-close-on-backdrop
           @show="onShow"
           @hide="onHide"
           :title="$t('treatment_plan')"
           centered>
    <div class="pb-5 patient-visit-wrap" v-if="object">
      <div v-if="object && object.updated_at && object.updated_user"
           class="text-muted small mb-3">
        * {{ $t('last_update') }}: {{ object.updated_user.full_name }}, {{ formatDateTime(object.updatedAtTimezoned) }}
      </div>
      <div>
        <b-row>
          <b-col lg="6">
            <InputGroup :label="$t('title')"
                        :required="true"
                        v-model="object.message"/>
            <div class="validation-error" v-if="errors['message']">{{ errors['message'].join(', ') }}</div>
          </b-col>
          <b-col lg="3">
            <SelectGroup :options="planTypes"
                         variant="white"
                         :label="$t('plan_type')"
                         :translate="true"
                         :showlabel="true"
                         v-model="object.plan_type"/>
          </b-col>
          <b-col lg="3">
            <SelectGroup :options="manualStatuses"
                         variant="white"
                         :label="$t('plan_treatment_status')"
                         :translate="true"
                         :showlabel="true"
                         v-model="object.manual_status"/>
          </b-col>
          <b-col lg="6">
            <InputGroup :label="$t('patient')"
                        :required="true"
                        :readonly="true"
                        v-model="patient.full_name"/>
          </b-col>
          <b-col lg="6">
            <SelectGroup :options="doctors"
                         title="full_name"
                         variant="white"
                         :label="$t('doctor')"
                         :showlabel="true"
                         v-model="object.doctor_id"/>
          </b-col>
        </b-row>
      </div>
      <AutosizeTextArea v-model="object.complaints"
                        :min-h="46"
                        :rows="1"
                        :label="$t('main_complaints')"/>
      <hr/>
      <PlanTreatmentDiscount :object="object"
                             v-if="initiated"
                             :cost-original="costOriginal"
                             :cost="costTotal"/>
      <hr/>
      <div class="sub-menu">
        <draggable
            class="d-inline-block"
            :scroll-sensitivity="200"
            :force-fallback="true"
            item-key="id"
            v-model="object.visits"
            @end="dragEnd">
          <template #item="{ element, index }">
            <a :class="{ active: index == visitIndex }"
               class="pointer"
               @click="changeIndex(index)">
              <span class="circle mr-2" :class="`status-${element.manual_status}`"></span>
              <template v-if="element.title">{{ element.title }}</template>
              <template v-else>{{ $t('complex') }} {{ index + 1 }}</template>
            </a>
          </template>
        </draggable>
        <a @click="addVisit" class="pointer">{{ $t('add_complex') }} +</a>
      </div>

      <PlanTreatmentModalVisit :key="`visit-tab-${visitIndex}`"
                               v-if="initiated && object.visits[visitIndex]"
                               v-model="object.visits[visitIndex]"
                               @deleteVisit="deleteVisit"
                               @applyTemplate="applyTemplate"
                               @costTotal="costs"
                               @servicesChanged="changedServices = true"
                               :templates="templates"
      />

    </div>
    <template #modal-footer="{ close }">
      <div class="modal-footer-buttons">
        <b-button class="ml-auto ml-sm-0 cancel d-block d-sm-inline-block align-middle"
                  variant="themed"
                  @click="close()">
          {{ $t('cancel') }}
        </b-button>
        <button :disabled="loading"
                @click="saveAsTemplate"
                class="mt-sm-0 mt-2 w-auto align-middle px-3 ml-sm-3 ml-0 d-block d-sm-inline-block btn-themed btn-themed-squared btn-themed-outline">
          {{ $t('save_as_template') }}
        </button>
        <button :disabled="loading"
                @click="save"
                class="ml-auto ml-sm-3 mt-sm-0 mt-2 btn-themed align-middle d-block d-sm-inline-block btn-themed-squared">
          {{ $t('save') }}
        </button>
      </div>
    </template>
  </b-modal>
</template>

<script>

import { mapActions, mapState } from "vuex"
import { CalendarService, EloquentService } from "@/services/api.service"
import InputGroup from "@/components/form/InputGroup"
import PlanTreatmentModalVisit from "@/components/parts/patients/PlanTreatmentModalVisit"
import PlanTreatmentDiscount from "@/components/parts/patients/PlanTreatmentDiscount"
import SelectGroup from "@/components/form/SelectGroup"
import AutosizeTextArea from "@/components/form/AutosizeTextArea"
import { planTreatmentTemplateAdapter } from "@/adapters/templates/planning_treatment_template"
import draggable from 'vuedraggable'
import { formatDateTime } from "@/extensions/filters/filters"
import { planTreatmentTypes } from "@/dictionaries/dictionary"

export default {
  name: "PlanTreatmentModal",
  components: {
    SelectGroup,
    PlanTreatmentModalVisit,
    PlanTreatmentDiscount,
    InputGroup,
    AutosizeTextArea,
    draggable
  },
  data() {
    return {
      initiated: false,
      object: null,
      loading: false,
      versionCount: 0,
      visitIndex: 0,
      // diagnoses: {},
      changedServices: false,
      templates: [],
      manualStatuses: [
        { id: 'confirmed', title: 'confirmed_male' },
        { id: 'rejected', title: 'rejected' },
        { id: 'stopped', title: 'stopped' },
        { id: 'treatment_complete', title: 'treatment_complete' },
        { id: 'active', title: 'active' },
      ],
      planTypes: planTreatmentTypes,
      currentVisitCostOriginal: 0,
      currentVisitCostDiscounted: 0
    }
  },
  computed: {
    ...mapState({
      visit:  state => state.patient.visit,
      patient:  state => state.patient.patient,
      errors: state => state.datatable.validationErrors,
      diagnoses: state => state.treatment.diagnoses,
      doctors: state => state.auth.doctors,
      branch: state => state.auth.branch,
    }),
    costsExceptForCurrent() {
      let cost = 0
      this.object.visits.forEach((visit, index) => {
        if(index != this.visitIndex) cost += visit.cost
      })
      return cost
    },
    originalCostsExceptForCurrent() {
      let cost = 0
      this.object.visits.forEach((visit, index) => {
        if(index != this.visitIndex) cost += visit.original_cost
      })
      return cost
    },
    costOriginal() {
      return (this.originalCostsExceptForCurrent + this.currentVisitCostOriginal)
    },
    costTotal() {
      return (this.costsExceptForCurrent + this.currentVisitCostDiscounted)
    }
  },
  methods: {
    formatDateTime,
    ...mapActions(['getClientPlan']),
    async addVisit() {
      let r = await this.save(false)
      if(r) {
        this.object.visits.push({
          services: {},
          diagnoses: {},
          manual_status: 'new'
        })
        this.visitIndex = this.object.visits.length - 1
      }
    },
    async save(final = true) {
      if(this.loading) return
      this.$store.commit('cleanValidationErrors')
      const visits = this.copyObject(this.object.visits).map(item => {
        return {
          ...item,
          fields: item.fields?.id ? [item.fields?.id] : []
        }
      })
      this.loading = true
      try {
        const res = await CalendarService.updatePlanTreatment(this.object.planning_id ? this.object.planning_id : 0, {
          discount_value: this.object.discount_value,
          discount_type: this.object.discount_type,
          message: this.object.message,
          complaints: this.object.complaints,
          plan_type: this.object.plan_type,
          manual_status: this.object.manual_status === '-' ? null : this.object.manual_status,
          client_id: this.object.client_id,
          doctor_id: this.object.doctor_id,
          visits,
          changedServices: this.changedServices,
        })
        this.$store.commit('setVisit', res.data)
        this.object = this.copyObject(res.data)
        this.loading = false
        if(final) {
          this.$noty.info(this.$t('success_message'))
          this.$bvModal.hide('plan-treatment-modal')
          this.$store.commit('incrementDataTableCounter')
        }
        if(!final) {
          return true
        }
      } catch(err) {
        this.loading = false
        if(!final) return false
      }
    },
    saveAsTemplate() {
      const title = prompt(this.$t('enter_the_title'))
      if(title != null) {
        this.loading = true
        let formData = new FormData()
        formData = this.appendFormdata(formData, planTreatmentTemplateAdapter(this.object.visits[this.visitIndex], true))
        formData.append('title', title)
        EloquentService.create('plan_treatment_template', formData).then(() => {
          this.loading = false
          this.$noty.info(this.$t('success_message'))
        }).catch(() => this.loading = false)
      }
    },
    deleteVisit() {
      this.object.visits.splice(this.visitIndex, 1)
      if(this.visitIndex > 0) this.visitIndex -= 1
    },
    async changeIndex(visitIndex) {
      if(this.visitIndex === visitIndex) return
      const r = await this.save(false)
      if(r) {
        this.visitIndex = visitIndex
        this.changedServices = false
      }
    },
    getPlanTemplates() {
      EloquentService.autocomplete('plan_treatment_template', '', 999).then(res => {
        this.templates = res.data.result
      })
    },
    getServiceCost(item) {
      return parseFloat(item.cost_branch)
      // return item.pivot.price_original
      // return this.view === 'plan' && this.versionCount > 0 ? parseFloat(item.cost_branch) : item.pivot.price_original
      // return this.view === 'plan' && this.versionCount > 0 ? parseFloat(item.cost_branch) : item.pivot.price_original
    },
    onShow() {
      this.visitIndex = 0
      this.changedServices = false
      if(this.visit.planning_id) {
        this.getClientPlan({
          id: this.patient.id,
          plan_id: this.visit.planning_id
        }).then(() => {
          this.object = this.copyObject(this.visit)
          this.initiated = true
        })
      } else {
        this.object = this.copyObject(this.visit)
        this.initiated = true
      }
      this.getPlanTemplates()
    },
    onHide() {
      this.$store.commit('setValidationErrors', {})
      this.$store.commit('nullifyTreatmentCard')
      this.initiated = false
      this.changedServices = false
    },
    applyTemplate(template) {
      this.object.message = template.title
    },
    costs(costTotal, costTotalDiscounted) {
      this.currentVisitCostOriginal = costTotal
      this.currentVisitCostDiscounted = costTotalDiscounted
    },
    async dragEnd(e) {
      const { newIndex, oldIndex } = e
      await this.save(false)
      if(oldIndex === this.visitIndex) {
        this.visitIndex = newIndex
        return
      }
      if(newIndex === this.visitIndex) {
        this.visitIndex = oldIndex
        return
      }
      if(newIndex === oldIndex) {
        this.visitIndex = oldIndex
      }
    }
  },
  created() {
    draggable.compatConfig = { MODE: 3 }
  },
}
</script>

<style scoped lang="scss">
.sub-menu {
  overflow: -moz-scrollbars-vertical;
}
.circle {
  width: 15px;
  height: 15px;
  &.status-new {
    background: $gray-text;
  }
  &.status-in_process {
    background: $warning-yellow;
  }
  &.status-finished {
    background: #65CA35;
  }
}
@media screen and (max-width: 768px) {
  .modal-dialog .modal-content .modal-footer .modal-footer-buttons .btn-themed {
    width: 80px;
  }
}
</style>
