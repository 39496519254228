export default {
    add_to_egisz: "Додати до ЕГИСЗ",
    diagnosis_comments: "Коментар до діагнозу",
    download_all: "Завантажити все",
    egisz: "ЕГИСЗ",
    for_rework: "На доопрацювання",
    funnel: "Воронка",
    list: "Список",
    main_doctor: "Головний лікар",
    patient_rejected_to_send_to_egisz: "Пацієнт відмовився відправляти дані в ЕГИСЗ",
    prepare_document_for_sending_to_egisz: "Підготувати документ до відправлення в ЕГИСЗ?",
    requires_attention: "Потребує уваги",
    representative_relation: "Ким є представник пацієнту",
    send_to_egisz: "Відправити в ЕГИСЗ",
    sent_to_egisz: "Відправлено до ЕГИСЗ",
    sign: "Підписати",
    sign_all: "Підписати все",
    signed_by_doctor: "Підписано лікарем",
    signed_by_main_doctor: "Підписано глав. лікарем",
    show_only_not_sent: "Показати тільки не відправлені",
    to_egisz_page: "На сторінку ЕГИСЗ",
    to_rework: "На доопрацювання",
    to_sign: "На підпис",
}
