export default {
    "-": "-",
    actions: "Действия",
    actions_with_selected: "Действия с выбранными",
    active: "Активен",
    add: "Добавить",
    add_attachment: "Добавить вложение",
    add_condition: "Добавить условие",
    all: "Все",
    app_is_in_maintenance_mode: "В приложениии ведутся технические работы. Мы обязательно скоро вернемся",
    are_you_sure_you_want_to_delete: "Вы уверены, что хотите удалить материал?",
    are_you_sure_you_want_to_logout: "Вы уверены, что хотите выйти?",
    authorization: "Авторизация",
    authorize_in_system: "Авторизуйтесь в системе",
    azerbaijani: "Азербайджанский",
    balance: "Депозит",
    cancel: "Отмена",
    change: "Изменить",
    change_password: "Изменить пароль",
    comment: "Комментарий",
    confirm_deletion: "Подтвердить удаление?",
    contacts: "Контакты",
    contains: "Содержит",
    continue: "Продолжить",
    currency: "Валюта",
    delete: "Удалить",
    does_not_equal: "Не равно",
    done: "Готово",
    down: "Вниз",
    edit: "Редактировать",
    email: "Email",
    english: "Английский",
    error: "Ошибка",
    error_message: "Что-то пошло не так, операция не была завершена успешно",
    equals: "Равно",
    excel: "Excel",
    file: "Файл",
    file_does_not_exist: "Файл не найден",
    file_size_is_too_big: "Размер файла слишком велик",
    filled: "Заполнено",
    filter: "Фильтр",
    finish: "Завершить",
    forgot_password: "Забыли пароль?",
    hide: "Спрятать",
    home: "Главная",
    image: "Изображение",
    import: "Импорт",
    input_field: "Поле для ввода текста",
    KZT: "KZT",
    language: "Язык",
    less: "Менее",
    load_more: "Загрузить еще",
    login: "Логин",
    login_or_pass_incorrect: "Логин и / или пароль неверны",
    logout: "Выйти",
    main: "Главная",
    message: "Сообщение",
    module_unavailable: "Модуль недоступен",
    months: "Месяцев",
    more: "Более",
    name: "Имя",
    no: "Нет",
    no_data_available: "Данных пока нет",
    nonactive: "Неактивен",
    not_enough_rights_for_this_operation: "Недостаточно прав для данной операции",
    nothing_found: "Ничего не найдено",
    old_password: "Старый пароль",
    on_page: "На странице",
    page_not_found: "Страница не найдена",
    password: "Пароль",
    password_changed: "Пароль изменен",
    password_confirm: "Подтверждение пароля",
    passwords_do_not_match: "Пароли не совпадают",
    patient_search: "Поиск пациента",
    payment_expires_at: "Оплата истекает",
    phone: "Телефон",
    photo: "Фото",
    price: "Цена",
    print_noun: "Печать",
    program: "Программа",
    profile: "Профиль",
    registration: "Регистрация",
    refresh: "Обновить",
    requirements: "Требования",
    reset: "Сброс",
    reset_password: "Сброс пароля",
    reset_success: "Письмо для сброса отправлено на ваш E-mail",
    role: "Роль",
    russian: "Русский",
    save: "Сохранить",
    search: "Поиск",
    select: "Выбрать",
    sent: "Отправлено",
    settings: "Настройки",
    signin: "Войти",
    show: "Показать",
    slogan: "Облачное решение для управления современной стоматологией",
    sorting: "Сортировка",
    start: "Начать",
    status: "Статус",
    subscription_sum: "Сумма за подписку",
    success_message: "Операция прошла успешно",
    sum: "Сумма",
    table_search: "Поиск по таблице",
    tech_specialist: "Технический специалист",
    text: "Текст",
    this_module_is_not_in_tariff: "Этот модуль не входит в тариф Free, вам нужно подключить Standard или Premium, и вы получите доступ к большому количеству функционала программы Dentist Plus.",
    title: "Название",
    today: "Сегодня",
    total: "Итого",
    translation: "Перевод",
    unknown: "Неизвестно",
    upload: "Загрузить",
    yes: "Да",
}
