export default {
    adjustment: "Düzəliş",
    add_all_materials_from_warehouse: "Bütün materialları anbardan əlavə et",
    add_material: "Material əlavə et",
    add_new: "Yeni əlavə et",
    adding_new_supplier: "Yeni təchizatçının əlavə olunması",
    address: "Ünvan",
    addition: "Gəlir",
    amount: "Miqdar",
    amount_of_stock_on_all_warehouses: "Klinikanın bütün anbarlarında mövcud olan ümumi mal miqdarı",
    approve: "Təsdiq et",
    approved: "Təsdiq olundu",
    are_you_sure_you_want_to_delete_group: "Qrupu silmək istədiyinizə əminsiniz?",
    are_you_sure_you_want_to_delete_kit: "Komplekti silmək istədiyinizə əminsiniz?",
    are_you_sure_you_want_to_delete_supplier: "Təchizatçını silmək istədiyinizə əminsiniz?",
    are_you_sure_you_want_to_delete_warehouse: "Anbarı silmək istədiyinizə əminsiniz?",
    artikul: "Artikul",
    bank_requisites: "Bank rekvizitləri",
    barcode: "Barkod",
    beginning_balance: "Başlanğıc qalığı",
    bin: "Biznes ID nömrəsi (BİN)",
    branch: "Filial",
    bulk_addition: "Qrup ilə əlavə et",
    by_categories: "Kateqoriyalar üzrə",
    consumption: "İstifadə",
    consumption_manual: "Əl ilə istifadə",
    contact_person: "Əlaqəli şəxs",
    cost: "Dəyər",
    created: "Yaradıldı",
    current_stock: "Cari qalıq",
    destination: "Təyinat",
    details: "Detallar",
    difference: "Fərq",
    documents: "Sənədlər",
    draft: "Qaralama",
    enter_title_or_barcode: "Başlığı və ya barkodu daxil edin",
    factual_amount: "Faktiki miqdar",
    finished: "Təsdiqləndi",
    from: "kimdən",
    from_warehouse: "Anbardan köçürmə",
    from_where: "Haradan",
    general_price: "Ümumi məbləğ",
    general_stock: "Ümumi qalıq",
    group: "Qrup",
    groups: "Qruplar",
    id: "ID",
    inventarization: "İnventarizasiya",
    Kit: "Komplekt",
    kit: "Komplekt",
    kits: "Komplektlər",
    material: "Material",
    materialgroup: "Qrup",
    materialstock: "Material",
    materials: "Materiallar",
    movement: "Köçürmə",
    movements: "Hərəkətlər",
    movement_from: "Köçürmə",
    movement_to: "Köçürmə",
    nomenclature: "Nomenklatura",
    notify_when_stock: "Qalıq azaldıqda xəbərdar et",
    operation: "Əməliyyat",
    parent_category: "Əsas kateqoriya",
    parent_group: "Əsas qrup",
    physical: "Fiziki",
    print: "Çap et",
    return: "Geri qaytarma",
    stocks: "Qalıqlar",
    stocks_archive: "Materiallar arxivi",
    supplier: "Təchizatçı",
    suppliers: "Təchizatçılar",
    to_warehouse: "Anbara köçürmə",
    to_where: "Hara",
    type: "Növ",
    unit_of_measure: "Ölçü vahidi",
    virtual: "Virtual",
    warehouse_amount: "Anbarda miqdar",
    warehouses: "Anbarlar",
    warehouseoperation: "Əməliyyatlar",
    write_off: "Silinmə"
}