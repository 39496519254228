<template>
  <b-modal id="refill-balance-modal"
           v-if="patient"
           @hide="onHide"
           @show="onShow"
           :title="$t('balance_refill')"
           centered
           hide-footer>
    <b-row>
      <b-col>
        <label class="form-label">{{ $t('balance') }}</label>
        <div class="emphasized-text">{{ formatCurrency(patient.advance_calculated) }}</div>
      </b-col>
      <b-col>
        <label class="form-label">{{ $t('debt') }}</label>
        <div class="emphasized-text text-danger">{{ formatCurrency(patient.debt_calculated) }}</div>
      </b-col>
    </b-row>
    <hr/>
    <b-row class="align-items-end">
      <b-col cols="6">
        <SelectGroup :options="finalPayMethods"
                     variant="white"
                     :label="$t('balance_refill')"
                     :translate="true"
                     :showlabel="true"
                     v-model="pay_method"/>
        <ValidationError validationId="form"/>
      </b-col>
      <b-col cols="6">
        <InputGroup type="number"
                    :label="$t('sum')"
                    :required="true"
                    v-model="amount"/>
        <ValidationError validationId="paid"/>
      </b-col>
      <b-col cols="12" v-if="deposit_accounts.length > 1">
        <SelectGroup :options="deposit_accounts"
                     variant="white"
                     :label="$t('deposit_account')"
                     :translate="true"
                     :null-option="false"
                     :showlabel="true"
                     v-model="deposit_account_id"/>
      </b-col>
      <b-col cols="12">
        <InputGroup :label="$t('comment')"
                    :required="false"
                    v-model="comment"/>
        <ValidationError validationId="comment"/>
      </b-col>
      <b-col cols="12" v-if="rights.includes('cashdesk') && rights.includes('aqsi')">
        <b-row class="align-items-center">
          <b-col cols="12">
            <CheckboxGroup :label="$t('fiscal_cheque')"
                           :fid="`ppo-checkbox`"
                           v-model="sendCheque"/>
          </b-col>
          <b-col cols="12" v-if="sendCheque">
            <SelectGroup :options="aqsiAccounts"
                         :null-option="true"
                         variant="white"
                         :label="$t('AQSI_account')"
                         :translate="false"
                         valuekey="key"
                         title="title"
                         :showlabel="true"
                         v-model="ppoAccount"/>
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="12">
        <div class="form-group">
          <b-dropdown right variant="link" no-caret>
            <template #button-content class="w-100">
              <button :disabled="disabled" class="pointer position-relative btn-themed btn-success btn-themed-squared btn-process d-block w-100">
                {{ $t('process') }}
              </button>
            </template>
            <div class="pt-0">
              <button :disabled="loading"
                      @click="save('N')"
                      class="btn w-100 text-left">
                {{ $t('filling') }}
              </button>
              <button :disabled="loading"
                      @click="save('Y')"
                      class="btn w-100 text-left">
                {{ $t('write_off') }}
              </button>
            </div>
          </b-dropdown>
        </div>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>

import { mapActions, mapState } from "vuex"
import InputGroup from "@/components/form/InputGroup"
import SelectGroup from "@/components/form/SelectGroup"
import { EloquentService } from "@/services/api.service"
import ValidationError from "@/components/form/ValidationError"
import CheckboxGroup from "@/components/form/CheckboxGroup.vue"
import {formatCurrency} from "@/extensions/filters/filters"

export default {
  name: "RefillBalanceModal",
  components: {
    CheckboxGroup,
    ValidationError,
    SelectGroup,
    InputGroup
  },
  data() {
    return {
      amount: 0,
      pay_method: 1,
      comment: '',
      ppoAccount: null,
      sendCheque: false,
      deposit_account_id: null,
      deposit_accounts: []
    }
  },
  methods: {
    formatCurrency,
    ...mapActions(['getPatient']),
    save(cancel) {
      let formData = new FormData()
      formData = this.appendFormdata(formData, {
        client_id: this.patient.id,
        cancel: cancel,
        form: this.pay_method,
        paid: this.amount,
        ppoAccount: this.ppoAccount,
        sendCheque: this.sendCheque ? 1 : 0,
        comment: this.comment,
        deposit_account_id: this.deposit_account_id
      })
      EloquentService.create('advance', formData).then(() => {
        this.getPatient(this.patient.id)
        this.$bvModal.hide('refill-balance-modal')
        this.$store.commit('incrementDataTableCounter')
      })
    },
    onHide() {
      this.amount = 0
      this.pay_method = 1
      this.comment = ''
      this.ppoAccount = null
      this.sendCheque = false
    },
    async onShow() {
      const res = await EloquentService.dataTable('deposit_account')
      this.deposit_accounts = res.data?.result?.data || []
      if(this.deposit_accounts.length) {
        this.deposit_account_id = this.deposit_accounts.find(d => d.is_default === 1)?.id || this.deposit_accounts[0].id
      }
    }
  },
  computed: {
    ...mapState({
      loading: state => state.dom.loading,
      patient: state => state.patient.patient,
      pay_methods: state => state.auth.pay_methods,
      rights: state => state.auth.rights,
      clinic: state => state.auth.clinic,
      branch: state => state.auth.branch,
    }),
    finalPayMethods() {
      return this.pay_methods.filter(x => ![5,100].includes(x.id)) // no deposit and family account
    },
    aqsiAccounts() {
      if(!this.rights.includes('aqsi')) return []
      const settings = this.clinic.ppo_settings ? JSON.parse(this.clinic.ppo_settings) : {}
      return settings[`aqsi_key${this.branch}`] || []
    },
    disabled() {
      if(this.amount <= 0) return true
      if(this.sendCheque && !this.ppoAccount) return true
      return false
    }
  },
}
</script>

<style scoped lang="scss">
.btn-success {
  height: 46px;
  line-height: 44px;
}
.dropdown {
  width: 100%;
}
@media screen and (max-width: 768px) {
  .btn-process {
    height: 32px;
    line-height: 30px;
  }
}
</style>
