<template>
  <b-modal id="patient-canban-modal"
           @show="onShow"
           @hide="onHide"
           hide-footer
           size="xl"
           centered>
    <div v-if="loaded && patientCanban && patient">
      <div class="row align-items-start">
        <div class="col-md col-12">
          <div>
            <h5 class="mb-2">{{ $t('card') }} #{{ patientCanban.id }}</h5>
          </div>
          <div>
            {{ $t('status') }}:
            <span class="font-weight-bold" :style="`color: ${statusObj.color};`">{{ $t(statusObj.title) }}</span>
          </div>
          <div>
            <span class="font-weight-bold">{{ $t('responsible') }}</span>:
            <div class="d-inline-block">
              <OneFieldEdit modelName="staff"
                            @input="oneFieldSave"
                            v-model="patientCanban.responsible"/>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-auto">
          <div>{{ $t('creation_date') }}: {{ formatDateTime(patientCanban.created_at) }}</div>
          <div>{{ $t('who_created') }}: {{ patientCanban.author ? patientCanban.author.full_name : '-' }}</div>
          <div>{{ $t('updated_date') }}: {{ formatDateTime(patientCanban.updated_at) }}</div>
          <div>{{ $t('who_updated') }}: {{ patientCanban.updater ? patientCanban.updater.full_name : '-' }}</div>
        </div>
      </div>

      <div class="mb-3 overflow-scroll nowrap mt-3">
        <div class="d-flex flex-nowrap">
          <button v-for="l in patientCanbanStatuses"
                  class="btn-themed btn-themed-outline btn-themed-squared mr-2 lead-status-btn"
                  :style="`border-color: ${l.color}; color: ${l.color};`"
                  @click="changeStatus(l.id)"
                  :key="`le-${l.id}`">
            {{ $t(l.title) }}
          </button>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="font-weight-bold">{{ $t('patient') }}</div>
          <div>
            <router-link :to="`/patients/${patient.id}/visits`">{{ patient.full_name }}</router-link>
            <span class="ml-2">
            <a class="circle mr-2 pointer"
               :style="`background-color: ${t.color}`"
               v-for="(t, tind) in patient.tags"
               :title="t.title"
               v-b-tooltip.hover
               :key="`made-tag-${tind}`"/>
          </span>
          </div>
          <div class="d-inline-flex align-items-center">
            {{ $t('phone') }}
            <div class="d-inline-flex align-items-center ml-2"
                 v-if="patient.mphone && !rights.includes('hide_patient_phone')">
              <OutgoingCallElement :phone="patient.mphone"
                                   color="black"
                                   class="mphone"/>
              <a class="ml-3"
                 :href="`tel:${patient.mphone}`"
                 v-b-tooltip.hover
                 :title="$t('call')">
                <PhoneSvg class="svg-primary"/>
              </a>
              <WhatsappTelegramIcon :patient-id="patient.id"
                                    :phone="patient.phone"/>

            </div>
          </div>
          <div v-if="patient.clientStatus">
            <span class="badge"
                  :class="'badge-'+patient.clientStatus">
              {{ $t(patient.clientStatus) }}
            </span>
          </div>
          <div class="mt-1">
            <span class="font-weight-bold">{{ $t('activity') }}</span>:
            {{ $t(patient.activity_status) }}
          </div>
          <div>
            <span class="font-weight-bold">{{ $t('treating_doctor') }}</span>:
            <div class="d-inline-block">
              <OneFieldEdit modelName="doctor"
                            v-model="patientCanban.doctor"
                            @input="oneFieldSave"
                            :autocompleteAdditionalParams="{ 'blocked': 0 }"/>
            </div>
          </div>
          <div>
            <span class="font-weight-bold">{{ $t('curator') }}</span>:
            <div class="d-inline-block">
              <OneFieldEdit modelName="curator"
                            @input="oneFieldSave"
                            v-model="patientCanban.curator"/>
            </div>
          </div>
          <div>
            <button v-if="patient"
                    class="btn-themed btn-success btn-themed-squared mt-3"
                    @click="recordPatient">
              {{ $t('record_on_visit') }}
            </button>
          </div>
        </div>
        <div class="col-md-6 mt-md-0 mt-3">
          <div>
            <div>
              <span class="font-weight-bold">{{ $t('treatment_plan') }}</span>:
              <div class="d-inline-block">
                <OneFieldEdit :options="planTreatments"
                              valueId="planning_id"
                              modelName="planning_treatment"
                              @input="oneFieldSave"
                              :dbSearch="false"
                              title="message"
                              v-model="patientCanban.treatment_plan"/>
              </div>
            </div>
            <div v-if="patientCanban.treatment_plan">
              <div v-for="visit in patientCanban.treatment_plan.visits"
                   :key="`vis-${visit.id}`"
                   class="visit-line">
                {{ visit.fullTitle }} ({{ $t(visit.manual_status) }})
                <span class="circle ml-2" :class="`status-${visit.manual_status}`"></span>
              </div>
            </div>
          </div>
          <div class="mt-3">
            <div><span class="font-weight-bold">{{ $t('last_visit') }}</span>: {{ visitString(patient.lastVisit, 'lastVisitDoctor') }}</div>
            <div><span class="font-weight-bold">{{ $t('next_visit') }}</span>: {{ visitString(patient.nextVisit, 'nextVisitDoctor') }}</div>
          </div>
        </div>
      </div>
      <div class="mt-3">
        <AutosizeTextArea v-model="patientCanban.comment"
                          :label="$t('comment')"/>
      </div>
      <hr/>
      <div class="row">
        <div class="col-md-6">
          <b-tabs nav-class="sub-menu"
                  content-class="pt-4"
                  class="w-100">
            <b-tab active :title="$t('comments')">
              <GeneralCommentsBlock :object="patientCanban"
                                    :black-label="false"
                                    model="patient_canban_comment"
                                    parent-column="patient_canban_id"/>
            </b-tab>
            <b-tab :title="$t('live_feed')">
              <PatientsCommentsList :patient="patient"
                                    :checked="patientCommentTypes.map(x => x.id)"
                                    :show-images="false"/>
            </b-tab>
          </b-tabs>
        </div>
        <div class="col-md-6">
          <b-row class="mb-3">
            <b-col>
              <button class="btn btn-themed btn-themed-outline btn-themed-squared w-100"
                      @click="addRecallTask">
                {{ $t('recall') }}
              </button>
            </b-col>
            <b-col>
              <button class="btn btn-themed btn-themed-outline btn-themed-squared w-100"
                      @click="addTask">
                {{ $t('add_task') }}
              </button>
            </b-col>
          </b-row>
          <div class="mt-3 mb-3" v-if="patientCanban.tasks && patientCanban.tasks.length">
            <TaskTeaser v-for="(task, tInd) in patientCanban.tasks"
                        :show-info="false"
                        @loadTasks="load"
                        :key="`task-${task.id}-${tInd}`"
                        :task="task"/>
          </div>
        </div>
      </div>
      <div class="modal-footer-buttons no-print text-right mt-4">
        <button class="btn-themed align-middle btn-themed-squared btn-outline-danger mr-3"
                @click="deleteCard">
          {{ $t('delete') }}
        </button>
        <button :disabled="loading"
                @click="save"
                class="btn-themed align-middle btn-themed-squared">
          {{ $t('save') }}
        </button>
      </div>
    </div>
  </b-modal>
</template>

<script setup>
import { patientCommentTypes } from "@/dictionaries/dictionary"
import { useStore } from "vuex"
import { computed, getCurrentInstance, onMounted, ref, watch } from "vue"
import { EloquentService } from "@/services/api.service"
import { appendFormdata, copyObject } from "@/extensions/prototypes/prototypes"
import WhatsappTelegramIcon from "@/components/pages/patients/WhatsappTelegramIcon.vue"
import PhoneSvg from "@/assets/svg-vue/header/phone_t.svg.vue"
import OutgoingCallElement from "@/components/parts/patients/OutgoingCallElement.vue"
import OneFieldEdit from "@/components/parts/general/OneFieldEdit.vue"
import AutosizeTextArea from "@/components/form/AutosizeTextArea.vue"
import TaskTeaser from "@/components/parts/header/TaskTeaser.vue"
import GeneralCommentsBlock from "@/components/parts/general/GeneralCommentsBlock.vue"
import moment from 'moment'
import { useRouter } from "vue-router"
import { formatDateTime } from "@/extensions/filters/filters"
import PatientsCommentsList from "@/components/parts/patients/comment/PatientsCommentsList.vue"

const store = useStore()
const router = useRouter()

const patientCanban = computed(() => store.state.crm.patientCanban)
const rights = computed(() => store.state.auth.rights)
const patient = computed(() => patientCanban.value.patient)
const counter = computed(() => store.state.datatable.dataTableCounter)
const user = computed(() => store.state.auth.user)
const patientCanbanStatuses = computed(() => store.state.auth.patientCanbanStatuses)
const statusObj = computed(() => patientCanbanStatuses.value.find(l => l.id === patientCanban.value.status_id))

let bvModal = null
let i18n = null
let noty = null
const loaded = ref(false)
const loading = ref(false)
const planTreatments = ref([])

onMounted(() => {
  const instance = (getCurrentInstance())
  bvModal = instance?.ctx?._bv__modal
  i18n = instance?.ctx?.$i18n
  noty = instance?.appContext?.app?.noty
})

const changeStatus = (status_id) => {
  saveCanban({ status_id })
}

const saveCanban = async (data, refresh = true) => {
  data.patient_id = patientCanban.value.patient_id
  if(!data.status_id) data.status_id = patientCanban.value.status_id
  const formData = appendFormdata(new FormData(), data)
  try {
    await EloquentService.update('patient_canban', patientCanban.value.id, formData, false, false)
    if(refresh) {
      store.commit('incrementDataTableCounter')
      await load()
    }
  } catch (e) {
    console.log(e)
  }
}

const load = async () => {
  if(!patientCanban.value) return
  const updated = await EloquentService.show('patient_canban', patientCanban.value.id)
  store.commit('setPatientCanban', copyObject(updated.data))
  loaded.value = true
}

const loadPlanTreatments = async () => {
  const res = await EloquentService.dataTable('plan_treatment', 1, {
    pagination: 999,
    condition: {
      client_id: patient.value.id
    }
  })
  planTreatments.value = res.data.result.data
}

const onShow = () => {
  load()
  loadPlanTreatments()
  if(!patientCanbanStatuses.value.length) {
    store.dispatch('getPatientCanbanStatuses')
  }
}

const onHide = () => {
  loaded.value = false
}

const addTask = () => {
  store.commit('setModule', 'crm')
  store.commit('setViewModel', 'task')
  store.commit('setSelectedModel', {
    task_type: 'task',
    importance: 'medium',
    patient_canban_id: patientCanban.value.id,
    phone: patient.value.mphone,
    patient_id: patient.value.id,
    patient: patient.value,
  })
  store.commit('setShowModal', true)
}

const addRecallTask = () => {
  store.commit('setModule', 'crm')
  store.commit('setViewModel', 'task')
  store.commit('setSelectedModel', {
    patient_canban_id: patientCanban.value.id,
    task_type: 'call',
    // title: i18n.t('recall_on_lead', { title: lead.value.title }),
    deadline: moment().add(1, 'days').format('YYYY-MM-DD HH:mm'),
    start: moment().add(1, 'days').format('YYYY-MM-DD HH:mm'),
    patient_id: patient.value.id,
    patient: patient.value,
    phone: patient.value.mphone || '',
    importance: 'high',
    user: user.value,
    user_id: user.value.id
  })
  store.commit('setShowModal', true)
}

const recordPatient = async () => {
  router.push({ name: 'Calendar' }).catch(() => {})
  store.commit('setPatientFromCall', copyObject(patient.value))
  bvModal.hide('patient-canban-modal')
}

const visitString = (visit, doctor) => {
  if(!visit) return '-'
  const doctorName = patient.value[doctor] ? `, ${patient.value[doctor].full_name}` : ''
  return `${visit.readableStart}${doctorName}`
}

const oneFieldSave = () => {
  setTimeout(() => {
    save(false)
  }, 20)
}

const save = async (hide = true) => {
  loading.value = true
  await saveCanban({
    doctor_id: patientCanban.value.doctor?.id || null,
    curator_id: patientCanban.value.curator?.id || null,
    treatment_plan_id: patientCanban.value.treatment_plan?.planning_id || null,
    responsible_user_id: patientCanban.value.responsible?.id || null,
    comment: patientCanban.value.comment
  }, hide)
  loading.value = false
  if(hide) {
    bvModal.hide('patient-canban-modal')
    noty.info(i18n.t('success_message'))
  }
}

const deleteCard = async () => {
  if(!confirm(i18n.t('confirm_deletion'))) return
  loading.value = true
  const res = await EloquentService.delete('patient_canban', patientCanban.value.id)
  store.commit('setPatientCanban', null)
  if(!res.data) return
  loading.value = false
  bvModal.hide('patient-canban-modal')
  store.commit('incrementDataTableCounter')
}

watch(counter, () => {
  load()
})
</script>
