export default {
    absent: "Yoxdur",
    abonplata: "Abonent haqqı",
    accountant_fio: "Baş mühasibin FİO-su",
    accrue_bonuses_on_bonus_payments: "Bonus ödənişlərinə bonuslar əlavə edin",
    activate_bonus_system: "Bonus sistemini aktivləşdirin",
    acquiring_fee: "Ekvarinq faizi %",
    add_field: "İstiqamət əlavə edin",
    add_user: "İstifadəçi əlavə edin",
    additional_doctors: "Əlavə həkimlər",
    additional_modules: "Əlavə modullar",
    additional_space: "Əlavə yer",
    admins_limit: "Administratorların limiti",
    Advance: "Depozit",
    advance_calculated: "Depozit",
    advance_payment: "Birdəfəlik ödəniş",
    after_discounts: "Endirimlərdən sonra",
    after_technic_deduction: "Texnik çıxıldıqdan sonra",
    after_visit: "Vizitdən sonra xatırlatma",
    akt_text: "Göstərilən xidmətlərin aktında qeyd",
    all_administrators: "Bütün administratorlar",
    all_visits: "Bütün girişlər",
    allow_negative_deposits: "Depozitin mənfi balansda olmasına icazə verin",
    api: "API",
    api_token: "API token",
    are_you_sure_you_want_to_delete_branch: "Filialı silmək istədiyinizə əminsiniz?",
    are_you_sure_you_want_to_delete_company: "Şirkəti silmək istədiyinizə əminsiniz?",
    are_you_sure_you_want_to_delete_notification: "Bildirişi silmək istədiyinizə əminsiniz?",
    are_you_sure_you_want_to_delete_source: "Mənbəni silmək istədiyinizə əminsiniz?",
    are_you_sure_you_want_to_delete_tag: "Etiketi silmək istədiyinizə əminsiniz?",
    ask_for_more: "Daha çox tələb et",
    ask_reason_cancel: "Vizitin ləğv edilmə səbəbini soruş",
    ask_reason_not_come: "'Gəlmədi' statusu üçün səbəbi soruş",
    ask_reason_reschedule: "Vizitin təxirə salınma səbəbini soruş",
    assistants_connected: "Assistentlər modulu qoşulub",
    available_disk_space: "Mövcud disk sahəsi, MB (0 - limitsiz)",
    automatic_conflict_task_creation: "Cədvəl konfliktində avtomatik tapşırıq yaradılması",
    before_discounts: "Endirimlərdən əvvəl",
    before_technic_deduction: "Texnik çıxılmadan əvvəl",
    before_visit: "Vizitdən əvvəl xatırlatma",
    billing: "Billinq",
    billing_payment: "Ödəniş",
    bonus_accrued: "Bonus əlavə edilib",
    bonus_adjustment: "Bonus tənzimləməsi",
    bonus_payment: "Bonus ilə ödəniş",
    bonus_percent_can_be_used: "Satışın maksimum hissəsi, bonus ilə ödənilə bilən",
    bonus_percent_of_cheque: "Satış üzrə bonus faizi",
    bonus_system: "Bonus sistemi",
    bonus: "Bonus",
    bonuses: "Bonuslar",
    branch_address: "Filialın ünvanı",
    branch_contacts: "Filialın əlaqələri",
    branch_inn: "Filialın VÖEN-i",
    branches: "Filiallar",
    branches_connected: "Filiallar modulu qoşulub",
    by_chairs: "Kreslolar üzrə",
    calendar_text_color: "Təqvimdə mətn rəngi",
    calendar_view_by_default: "Təqvimin standart görünüşü",
    cancel_and_move_reason: "Ləğv və köçürmə səbəbi",
    cancel_and_move_reasons: "Ləğv və köçürmə səbəbləri",
    change_tariff: "Tarifi dəyişdir",
    ClientNote: "Qeyd",
    client_tag: "Etiket",
    clinic: "Klinika",
    clinics: "Klinikalar",
    clinic_data: "Klinika məlumatları",
    clinic_inn: "Klinikanın VÖEN-i",
    clinic_is_blocked: "Klinika bloklanıb",
    clinic_name: "Klinikanın adı",
    clinic_name_in_cheque: "Çekdə klinikanın adı",
    close: "Bağla",
    cloud_space: "Bulud sahəsi",
    city: "Şəhər",
    communications: "Kommunikasiyalar",
    connected_successfully: "Uğurla qoşulub",
    connection_failed: "Bağlantı xətası",
    control_system: "Nəzarət sistemi",
    countries: "Ölkələr",
    country: "Ölkə",
    crm_application_created: "CRM müraciətinin yaradılması",
    crm_connected: "CRM modulu qoşulub",
    currency_rates: "Valyuta məzənnələri",
    data: "Məlumatlar",
    date_of_registration: "Qeydiyyat tarixi",
    day: "Gün",
    deduct_acquiring_from_salary: "Ekvarinqi maaşdan çıxmaq",
    deleted: "Silindi",
    demo: "Demo",
    director_fio: "Direktorun FİO-su",
    disk_space_limit: "Disk sahəsinin limiti (MB)",
    do_not_send_notifications_if_period_passed: "Müddət keçibsə, vizit barədə bildiriş göndərmə",
    Doctor: "Həkim",
    DoctorAssistant: "Assistent",
    doctors_limit: "Həkimlərin limiti",
    egisz_prod: "TƏBİB-nin iş rejimi",
    error_code: "Xəta kodu",
    finance: "Maliyyə",
    free: "Pulsuz",
    free_day: "Pulsuz gün",
    general: "Ümumi",
    has_additional_doctors: "Əlavə həkimlər var",
    higher_number_higher_priority: "Nömrə nə qədər yüksəkdirsə, siyahıda seçim bir o qədər yüksəkdir",
    hour: "saat",
    hours: "saatlar",
    image_hidden: "Şəkil gizlədilib",
    in_development: "İnkişafda",
    insurance_company: "Sığorta şirkəti",
    integrations: "İnteqrasiyalar",
    interface: "İnterfeys",
    ioka: "Ioka",
    jetapi_connected: "JET API modulu qoşulub",
    'kit materials sync': "Komplekt materiallarının sinxronizasiyası",
    kpp: "KPP",
    last_login: "Son giriş",
    legal_address: "Hüquqi ünvan",
    legal_name: "Hüquqi ad",
    license: "Lisenziya",
    license_issue_body: "Lisenziyanı verən orqan",
    license_issue_date: "Lisenziya verilmə tarixi",
    license_num: "Lisenziya #",
    location_on_map: "Xəritədə yer",
    logs: "Jurnal",
    logs_journal: "Fəaliyyət jurnalı",
    logo: "Loqo",
    logout_interval_timeout: "Fəaliyyətsizlikdə çıxış intervalı (administratorlar üçün)",
    max_doctor_amount: "Maksimum həkim sayı",
    Manager: "Administrator",
    manager2: "Menecer",
    manual: "Əl ilə",
    min: "dəqiqə",
    min_symbols: "Minimum simvollar",
    minute: "dəqiqə",
    minutes: "dəqiqələr",
    model_id: "Model ID",
    model_type: "Model növü",
    month: "Ay",
    monthly_payment: "Aylıq ödəniş",
    months_to_consider_patient_lost: "Pasiyent neçə aydan sonra itirilmiş hesab olunur",
    more_than_one_day: "Bir gündən çox",
    more_than_one_visit: "Bir vizitdən çox",
    next_payment: "Növbəti ödəniş",
    not_connected: "Qoşulmayıb",
    notification_channel: "Çatdırılma növü",
    notification_setting: "Bildiriş",
    NotificationSetting: "Bildiriş",
    notification_type: "Bildiriş növü",
    notifications: "Bildirişlər",
    occupied: "Məşğul",
    ogrn: "Qeydiyyat nömrəsi",
    oid_ministry_rf: "Səhiyyə Nazirliyinin OID-i",
    on: "Açıqdır",
    on_pay_page: "Ödəniş səhifəsinə",
    online_booking: "Onlayn yazılış",
    online_booking_activate: "Onlayn yazılışı aktivləşdir",
    online_booking_categories: "Xidmət kateqoriyalarını göstər",
    online_booking_feedbacks: "Rəy funksiyasını aktivləşdir",
    online_booking_map: "Filialın xəritəsini göstər",
    online_booking_max_days: "Yazılışın maksimum müddəti (günlərlə)",
    online_booking_time: "Təqvimdə vaxtı bron etmək",
    only_after_payment_received: "Yalnız pasiyentdən ödəniş alındıqda",
    paid_functionality: "Ödənişli funksiya",
    paid_till: "Ödənilib",
    Patient: "Pasiyent",
    patient_conditions: "Pasiyentlərin vəziyyətləri",
    patient_sources: "Pasiyentlərin mənbələri",
    patients_import: "Pasiyentlərin immportu",
    pay: "Ödəniş",
    Payment: "Ödəniş",
    payment_id: "Ödəniş ID",
    pay_methods: "Ödəniş üsulları",
    pay_methods_acquiring: "Ekvarinq-terminala ötürülən ödəniş üsulları",
    pay_methods_cash: "Nağd kimi göstərilən ödəniş üsulları",
    pay_system: "Ödəniş sistemi",
    percent_accrual_for_administrator: "Administratorlara faiz əlavə olunması",
    percent_accrual_for_personnel: "Personal üçün faiz əlavə olunması",
    permanent_patients_hint: "Pasiyenti daimi hesab etmək: a) onun minimum iki viziti varsa, 'gəldi' statusu və ya hesabında xidmətlər göstərilibsə b) minimum iki fərqli gündə vizitləri varsa",
    period_by_default: "Standart dövr",
    plan_treatment_text: "Müalicə planında qeyd",
    PlanTreatment: "Müalicə planı",
    popup: "Popap",
    price_per_additional_doctor: "Əlavə həkim üçün qiymət",
    price_per_additional_space: "Əlavə yerin qiyməti (100 MB)",
    program_language: "Proqram dili",
    prolong: "Uzadın",
    purchase: "Satınalma",
    record_tag: "Vizit üçün etiket",
    record_tags: "Vizitlər üçün etiketlər",
    record_timing_enabled: "Vizitlərin başlanğıcı və sonu üzrə nəzarət sistemi",
    Record: "Yazı",
    RecordTooth: "Diş",
    recipient: "Alıcı",
    required_fields_to_close_visit: "Viziti bağlamaq üçün tələb olunan sahələr",
    requires_payment: "Əlavə ödəniş tələb olunur",
    right: "Sağ",
    rights: "Hüquqlar",
    salary_accrual: "Həkimlərə / administratorlara / kuratorlara faizlərin hesablanması loqikası",
    salary_accrual_for_personnel: "Həkimlərə / administratorlara / kuratorlara maaşın hesablanması",
    salary_accrual_for_technics: "Texniklər üçün maaşın hesablanması",
    send_time: "Göndərilmə vaxtı",
    sendouts_only_in_day_time: "Maaş göndərişlər yalnız gündüz saatlarında (09.00 - 22.00) göndərilsin",
    server_error: "Sistem sorğunuzu yerinə yetirə bilmədi. Zəhmət olmasa yenidən cəhd edin və ya dəstək xidmətinə müraciət edin",
    services_import: "Xidmətlərin idxalı",
    show_complex_materials_for_teeth: "\"Dişlər\" bölməsində komplekt və materialları göstər",
    show_description_in_forms: "Vizit təsvirini forma 043, 058-də göstər",
    show_images: "Şəkilləri göstər",
    show_preinstalled_templates: "Quraşdırılmış şablonları göstər",
    show_teeth_general: "\"Dişlər\" bölməsində ümumi sahələri göstər",
    sort_order: "Sıralama qaydası",
    source: "Mənbə",
    superadmin: "Superadministrator",
    superadministrators: "Superadministratorlar",
    supermanager: "Supermenecer",
    super_user: "Superistifadəçi",
    switched_to_version_2: "Versiya 2.0-a keçdi",
    sync_data: "Məlumatları sinxronlaşdır",
    tag: "Etiket",
    tag_id: "Etiket ID",
    tags: "Etiketlər",
    tags_for_custom_notifications: "Avtomatik məlumat çıxarılması üçün tətbir etiketləri:",
    tags_for_notifications: "Avtomatik məlumat çıxarılması üçün etiketlər:",
    tariff: "Tarif",
    tariff_payment_expires_in_days: "Tarif üzrə ödəniş bu gün bitir | Tarif üzrə ödəniş {day} gün sonra bitir | Tarif üzrə ödəniş {day} gün ərzində bitir",
    tariffs: "Tariflər",
    task_created: "Tapşırıq yaradıldı",
    technic_salary_accrual: "Texniklər üçün faizlərin hesablanması loqikası",
    templates: "Şablonlar",
    templates_agreements: "Razılıq şablonları",
    templates_categories: "Standart kateqoriyalar",
    templates_contracts: "Müqavilə şablonları",
    templates_for_fields: "Sahələr üçün şablonlar",
    templates_text: "Mətn şablonları",
    templates_treatments: "Müalicə şablonları",
    techsupport_panel: "Texniki dəstək paneli",
    test: "Test",
    this_may_take_several_minutes_do_not_close_the_window: "Bu bir neçə dəqiqə çəkə bilər. Pəncərəni bağlamayın",
    time_after_visit: "vizitdən sonra",
    time_before_visit: "vizitdən əvvəl",
    time_interval_value: "Vaxt intervalının dəyəri",
    time_zone: "Saat qurşağı",
    to_billing: "Billinq ayarlarına",
    to_the_one_who_accepted_payment: "Ödənişi qəbul edənə",
    to_the_one_who_made_record: "Yazını edənin",
    treatment_finished: "Müalicə tamamlandı",
    turn_off: "Söndür",
    turned_off: "Söndürüldü",
    turned_on: "Yandırıldı",
    'visit services sync': "Xidmətlərin sinxronizasiyası",
    ukrainian: "Ukrayna",
    unblock_for_3_days: "3 gün üçün blokdan çıxar",
    updated: "Yeniləndi",
    User: "İstifadəçi",
    use_bonuses_on_salary: "Bonus ödənişləri ilə əmək haqqı hesablanması",
    use_proxy: "Proxy istifadə edin",
    user_id: "İstifadəçi ID",
    uzbek: "Özbək",
    visit_cancel: "Vizitin ləğvi",
    visit_created: "Vizit yaradıldı",
    warehouse_connected: "Anbar modulu qoşulub",
    week: "Həftə",
    when_changing_tariff_all_payments_will_nullify: "Tarifi dəyişdirərkən bütün ödənişlər ləğv ediləcək",
    which_branches_use_online_booking: "Hansı filiallar onlayn yazılışı istifadə edir",
    which_clients_to_consider_permanent: "Hansı müştəriləri daimi hesab etmək",
    work_time_from: "İş vaxtı ilə",
    work_time_to: "İş vaxtı qədər",
    your_tariff_plan_is_not_paid: "Tarif planınız ödənilməyib",
    zero_unlimited: "0 - limitsiz",
}
