export default {
    f37u78: "037/У-88 shakli",
    f43kaz: "043/КАЗ shakli",
    f43rus: "043/РФ shakli",
    f43ukr: "043/У shakli",
    f58kaz: "058/КАЗ shakli",
    accept_another_payment: "Boshqa to'lovni qabul qiling",
    activity: "Faollik",
    add_complex: "Kompleks qo'shing",
    add_diagnosis: "Tashxis qo‘shish",
    add_question: "Savol qo‘shish",
    add_service: "Xizmat qo‘shish",
    add_tooth_jaw: "Tish/jag' qo'shing",
    add_visit: "Tashrif qo‘shish",
    added_by: "Kim tomondan qo‘shildi",
    additional_discount: "Qo‘shimcha chegirma",
    age: "Yosh",
    agreement: "Ruxsat",
    akt_of_services_for_period: "Davr uchun ko'rsatilgan xizmatlar akti",
    akt_print: "Dalolatnomani bosib chiqarish",
    allergic_reactions: "Allergik reaksiyalar",
    allergy_diagnosis: "Allergologik diagnostika",
    anamnesis: "Stomatologik anamnez",
    applied: "Qo‘llanildi",
    applied_complex: "Amaliy kompleks",
    apply_to_teeth: "Tishlarga qo‘llang",
    apply_to_whole_tooth: "Barcha tishlarga qo‘llang",
    apply_treatment_template: "Davolash shablonini qo‘llang",
    are_you_sure_to_set_plan: "Siz davolanish kartochkangizni rejaga bog‘lamoqchimisiz - kartochkaning to‘ldirilgan maydonlarini qayta yozish mumkinmi?",
    are_you_sure_you_want_to_delete_document: "Haqiqatan ham hujjatni o‘chirmoqchimisiz?",
    are_you_sure_you_want_to_delete_payment: "Haqiqatan ham to‘lovni o‘chirib tashlamoqchimisiz?",
    are_you_sure_you_want_to_delete_scan: "Haqiqatan ham skanerni o‘chirmoqchimisiz?",
    are_you_sure_you_want_to_delete_status: "Haqiqatan ham holatni oʻchirib tashlamoqchimisiz?",
    are_you_sure_you_want_to_delete_transaction: "Haqiqatan ham tranzaksiyani o‘chirib tashlamoqchimisiz?",
    are_you_sure_you_want_to_delete_user: "Haqiqatan ham foydalanuvchini o‘chirib tashlamoqchimisiz?",
    are_you_sure_you_want_to_delete_users: "Haqiqatan ham foydalanuvchilarni o‘chirib tashlamoqchimisiz?",
    assistant: "Yordamchi",
    attach_new_user: "Yangi foydalanuvchini bog‘lash",
    author: "Muallif",
    available: "Mavjud",
    baby_teeth: "Sut tishlari",
    balance_refill: "Depozitni to‘ldirish",
    balance_writeoff: "Depozitni hisobdan chiqarish",
    birthdays: "Tug‘ilgan kunlar",
    bite: "Tishlam",
    bottom_baby_teeth: "Pastki sut tishlari",
    bottom_jaw: "Pastki jag‘",
    bottom_molars: "Pastki doimiy tishlar",
    by_doctors: "Shifokorlar bo‘yicha",
    by_complexes: "Komplekslar bo'yicha",
    call: "Qo‘ng‘iroq qilish",
    came: "Keldi",
    came_from: "Qayerdan keldi",
    can_be_paid_with_bonuses: "Bonuslar bilan to'lash mumkin",
    cancel_initiator: "Bekor qilish tashabbuskori",
    cancelled: "Bekor qilindi",
    card: "Kartochka",
    card_number: "Kartochka raqami",
    card_transfer: "Kartaga o‘tkazma",
    cash: "Naqd pul",
    cashless: "Naqd pulsiz",
    choose_document_type_and_its_parameters: "Hujjat turini va uning parametrlarini tanlang",
    choose_patient: "Bemorni tanlang",
    choose_treatment_template: "Davolash shablonini tanlang",
    client: "Mijoz",
    client_document: "Bemor hujjati",
    client_id: "Mijoz",
    client_note: "Eslatma",
    client_service: "Mijozlarga xizmat ko'rsatish",
    code: "Kod",
    color: "Rang",
    combine_user_note: "Bemorni tanlab, \"birlashtirish\" tugmasini bosib, tashriflar, to‘lovlar, tashxislar va boshqalar haqidagi barcha ma’lumotlarni tanlangan bemorning kartochkasiga o‘tkazasiz. Hozirgi bemorning kartochkasi o‘chiriladi. Birlashtirish tartibini bekor qilib bo‘lmaydi.",
    comment_to_phone: "Telefonga izohlar",
    comment_to_phone_2: "Qo‘shimcha telefonga izohlar",
    complaints: "Shikoyatlar",
    complex: "Murakkab",
    complexes: "Komplekslar",
    condition: "Ahvol",
    confirm_tag_deletion: "Haqiqatan ham tegni olib tashlamoqchimisiz?",
    confirmed: "Tasdiqlandi",
    confirmed_male: "Tasdiqlandi",
    contract: "Shartnoma",
    contract_agreement: "Kelishuv shartnomasi",
    contract_general: "Umumiy shartnoma",
    contract_print: "Shartnoma nashri",
    contract_services: "Xizmat ko‘rsatish to‘g‘risida shartnoma",
    consultation: "Maslahat",
    create_diagnosis: "Tashxis yaratish",
    creation_date: "Yaratish sanasi",
    date: "Sana",
    date_add: "Yaratildi",
    date_from: "Sanadan",
    date_of_birth: "Tug‘ilgan kun",
    date_to: "Sanagacha",
    dates_not_selected: "Hech qanday sana tanlanmagan",
    debt: "Qarz",
    debt_cancellation: "Qarzni bekor qilish",
    delete_complex: "Kompleksni o'chirish",
    delete_visit: "Tashrifni o‘chirish",
    department_code: "Bo'lim kodi",
    deposit: "Depozit",
    description: "Tavsif",
    desired_date_from: "Istalgan sanadan",
    desired_date_to: "Istalgan sanagacha",
    desired_time_from: "Istalgan vaqtdan",
    desired_time_to: "Istalgan vaqtgacha",
    detailed_information: "Batafsil ma’lumot",
    diagnoses: "Tashxislar",
    diagnosis: "Tashxis",
    did_not_come: "Kelmadi",
    discount: "Chegirma",
    disease_anamnez: "Kasallik tarixi (hozirgi kasallikning rivojlanishi)",
    disease_development: "Mazkur kasallik rivojlanishi",
    disease_history: "Kasallik tarixi",
    diseases_passed_and_current: "Kechib o‘tgan va hamroh kasalliklar",
    doctor: "Shifokor",
    document: "Hujjat",
    document_number: "Hujjat raqami",
    document_type: "Hujjat turi",
    drag_file_here_or_select: "Faylni bu yerga olib keling yoki tanlang",
    enter_the_title: "Nomini kiriting",
    epikriz: "Epikriz",
    examination_plan: "Tekshiruv rejasi",
    f043: "043 Sh",
    family_account: "Oilaviy hisob",
    female: "Ayol",
    female_short: "Ayol",
    fill_anamnez_from_previous_visits: "Oldingi tashriflardan anamnezni to‘ldiring",
    fill_balance: "Depozitni to‘ldirish",
    filling: "To‘ldirish",
    finish_visit: "Qabul qilishni tugatish",
    fio: "FIO",
    first_visit: "Birinchi qabul",
    fiscal_cheque: "Fiskal chek",
    for_female_patients: "Ayol bemorlar uchun",
    formula: "Formula",
    foto_link_note: "Suratni yuklashdan tashqari, siz rasmga umumiy havolani belgilashingiz mumkin",
    from_account: "Hisobdan",
    full_card: "To‘liq kartochka",
    gender: "Jins",
    general_info: "Asosiy ma'lumotlar",
    hygiene_index: "Gigiena indeksi",
    iin: "IIN",
    in_chair: "O‘rindiqda",
    in_process: "Jarayonda",
    include_in_f043: "043 shakliga kiritish",
    include_in_f058: "058 shakliga kiritish",
    inspection: "Tashqi ko‘rik",
    insurance: "Sug‘urta",
    invoice_print: "Hisobni chop etish",
    importance: "Ahamiyat",
    important: "Muhim",
    inn: "STIR",
    is_deleted: "O‘chirilgan",
    issue_date: "Berilgan sana",
    issued_by: "Kim tomonidan chiqarilgan",
    jaws: "Jag‘lar",
    kpu_index: "KPU indeksi",
    last_visit: "Oxirgi tashrif",
    left_due: "Qoldiq",
    life_anamnez: "Hayot tarixi",
    link: "Havola",
    link_to_call_record: "Suhbat yozuviga havola",
    listen: "Eshiting",
    live_feed: "Jonli lenta",
    lost: "Yo‘qotilgan",
    make_payment: "To‘lovni amalga oshirish",
    male: "Erkak",
    male_short: "Erkak",
    manual_status: "Ish holati",
    medication: "Davolash",
    middle_name: "Otasining ismi",
    mkb_code: "MKB kod",
    move_to_visit: "Tashrifga boring",
    mphone: "Telefon",
    ndfl_statement: "Malumot NDFL",
    ndfl_statement_new: "Malumot NDFL (yangi)",
    new: "Yangi",
    new_patient: "Yangi bemor",
    next_visit: "Keyingi tashrif",
    no_attachment: "Bog‘lanmagan",
    no_complexes: "Komplekslar yo'q",
    no_teeth_in_the_record: "Yozuvda bitta ham tish yo‘q",
    norm: "Me’yor",
    not_confirmed: "Tasdiqlanmagan",
    note: "Eslatma",
    notes: "Eslatmalar",
    objectively: "Xolisona",
    on_week: "Haftaga",
    or_write_other_reason: "yoki boshqa sababni yozing",
    oral_cavity: "Og‘iz bo‘shlig‘i",
    ortodentic_formula: "Ortodontik formulalar",
    ortodentic_formula_ru: "Ortodontik formulalar (RF)",
    other: "Boshqa",
    overpayment: "Ortiqcha to‘langan pul",
    paid: "To‘landi",
    passive: "Passiv",
    passport: "Pasport",
    passport_data: "Pasport ma'lumotlari",
    passport_number: "Pasport raqami",
    password_validation_error: "Parol kamida 8 ta belgidan iborat bo'lishi, kamida bitta bosh harf, kamida bitta raqam va kamida bitta maxsus belgidan iborat bo'lishi kerak.",
    patient: "Bemor",
    patient_condition_id: "Ahvol",
    patient_deleted: "Bemor o‘chirildi",
    patient_fio: "Bemor FIO",
    patient_has_important_notes: "Bemorda muhim belgilar mavjud: {cnt}",
    patient_number: "Bemor raqami",
    patient_phone: "Bemor telefoni",
    patient_source: "Qayerdan keldi",
    payment: "To‘lov",
    payment_accepted: "To‘lov qabul qilindi",
    payment_date: "To'lov sanasi",
    payment_form: "To‘lov shakli",
    payment_history: "To‘lovlar tarixi",
    payment_processing: "To‘lov o‘tkazilishi",
    payment_type: "To'lov usuli",
    payments: "To‘lovlar",
    permanent_teeth: "Doimiy tishlar",
    personal_data: "Shaxsiy ma’lumotlar",
    pesel: "PESEL",
    phone_2: "Qo‘shimcha telefon",
    pin: "Tuzatish",
    pinned: "Tuzilgan",
    plan_fact: "Reja / Haqiqat",
    plan_notification: "Xabarnomani rejalashtirish",
    planned_result: "Rejalashtiriladigan natija",
    planned_send_date: "Rejalashtiriladigan yuborish sanasi",
    planned_services: "Rejalashtiriladigan muolajalar",
    planned_treatment: "Rejalashtiriladigan davolanish",
    preliminary: "Dastlabki",
    price_price: "Narxi",
    prices_price: "Narxlar",
    print_empty: "To‘ldirilmagan nashr",
    process: "O‘tkazmoq",
    questionnaire: "Anketa",
    quick_card: "Tez kartochka",
    receipt: "Kvitansiya",
    recommendations: "Tavsiyalar",
    record: "Yozuv",
    record_not_available: "Yozib olish imkonsiz",
    recorded: "Yozilgan",
    refresh_prices: "Narxlarni yangilash",
    region: "Viloyat",
    regular_client: "Doimiy mijoz",
    rejected: "Rad etdi",
    reopen_visit: "Qabulni qayta oching",
    representative: "Vakil FIO",
    representative_address: "Vakillik manzili",
    representative_document: "Bemorni vakillik qilish huquqini tasdiqlovchi hujjatning tafsilotlari",
    representative_document_number: "Vakillik qilish huquqini tasdiqlovchi hujjat raqami",
    representative_document_type: "Vakillik qilish huquqini tasdiqlovchi hujjat turi",
    representative_id: "Bemorlar ro'yxatidan vakil",
    representative_inn: "Vakilning INN raqami",
    representative_noun: "Vakil",
    representative_passport: "Vakilning pasport raqami",
    representative_passport_data: "Vakilning pasport ma'lumotlari",
    representative_phone: "Vakil telefoni",
    request_snils: "SNILS so‘r",
    request_snils_note: "EGISZ dan SNILS olish - buning uchun siz bemorning ismlarini, jinsini, tug'ilgan sanasini va pasport ma'lumotlarini to'ldirishingiz kerak (turi, seriyasi, raqami, chiqarilgan sanasi va chiqarilgan organi)",
    restore: "Qayta tiklash",
    results_not_found: "Natijalar topilmadi",
    results_of_xray_and_lab_research: "Rentgen, laboratoriya tadqiqotlari ma’lumotlari",
    save_as_template: "Shablon sifatida saqlash",
    save_as_treatment_template: "Davolash shabloni sifatida saqlash",
    save_and_close: "Saqlash va yopish",
    scan: "Снимок",
    scans: "Surat",
    select_document_type: "Hujjat turini tanlang",
    select_visit: "Tashrifni tanlang",
    selected_teeth: "Tanlangan tishlar",
    send: "Yuborish",
    send_mail: "Xat yuborish",
    send_marketing: "Marketing elektron pochta xabarlarini yuboring",
    send_message: "Xabar yuborish",
    send_notifications: "Bildirishnomalarni yuborish",
    send_password_to_patient: "Parolni bemorga yuborish",
    send_try: "Yuborishga urinish",
    series: "Seriya",
    service: "Xizmat",
    services_payment_from: "Ko‘rsatilgan xizmatlar uchun to‘lov",
    set_the_amount: "Miqdorini belgilang",
    size: "O‘lcham",
    sms_available: "SMS mavjud",
    sms_sending: "SMS yuborish",
    snils: "SNILS",
    sorting_instructions: "Kerakli tartibda joylashtirish uchun qatorlarni sichqoncha bilan torting",
    standard: "Standart",
    start_treatment: "Davolanishni boshlash",
    start_visit: "Uchrashuvni boshlang",
    stopped: "To‘xtatilgan",
    subjectively: "Noxolis",
    sum_with_discount: "Chegirma bilan summa",
    superadministrator: "Superadministrator",
    surname: "Familiya",
    short_name: "Qisqa nomlanishi",
    symbols_amount: "Belgilar soni",
    tags_for_contract: "Ma’lumotni avtomatik tarzda chiqarish uchun teglar:",
    tags_for_document: "Ma’lumotni avtomatik tarzda chiqarish uchun teglar:",
    teeth: "Tishlar",
    teeth_card: "Tish kartasi",
    termometry: "Termometriya",
    there_were_no_payments: "To‘lov hali amalga oshirilmagan",
    this_phone_is_in_system: "Ushbu raqam allaqachon bazada mavjud",
    to_account: "Hisobga",
    to_pay_with_bonus: "Bonuslar bilan to'lash",
    to_whom: "Kimga",
    tonometry: "Tonometriya",
    tooth: "Tish",
    topic: "Mavzu",
    treatment_template: "Davolanish shabloni",
    tooth_is_assigned: "Tishni olib tashlab bo‘lmaydi - xizmatlar yoki tashxislar allaqachon unga bog‘langan",
    tooth_formula: "Tish formulasi",
    top_baby_teeth: "Yuqori sut tishlar",
    top_jaw: "Yuqori jag‘",
    top_molars: "Yuqori doimiy tishlar",
    to_add_diagnoses_you_need_to_select_teeth: "Tashxis qo‘yish uchun tishlarni tanlash kerak",
    to_add_services_you_need_to_select_teeth: "Xizmatlarni qo‘shish uchun tishlarni tanlash kerak",
    total_paid: "Jami to‘landi",
    total_paid_for_treatment_plan: "Davolash rejasiga muvofiq xizmatlar uchun to'lanadi",
    total_services_selected: "Tanlangan xizmatlar jami",
    total_to_pay: "Jami to‘lanishi kerak",
    total_with_discount: "Chegirma bilan jami",
    transfer_between_patient_accounts: "Bemor hisoblari o'rtasida o'tkazish",
    transfer_whole_amount: "Jami summani o‘tkazish",
    treating_doctor: "Davolovchi shifokor",
    treatment: "Davolanish",
    treatment_card: "Davolanish kartochkasi",
    treatment_complete: "Davolanish tugadi",
    treatment_plan: "Davolanish rejasi",
    un: "dona",
    unify: "Birlashtirish",
    unify_card: "Kartochkani birlashtirish",
    unpaid: "To‘lanmagan",
    unpin: "Yechish",
    upload_date: "Yuklash sanasi",
    used_medication: "Qabul qilingan dorilar",
    user_attached_to_family_account: "Foydalanuvchi oilaviy hisobga bog‘langan",
    users_attached_to_user_family_account: "Foydalanuvchining oilaviy hisobiga bog‘langan bemorlar",
    visit: "Tashrif",
    visit_date: "Tashrif sanasi",
    visit_duration: "Tashrif davomiyligi (daqiqada)",
    visit_finished: "Qabul tugadi",
    visit_in_progress: "Qabul davom etmoqda",
    visit_started: "Qabul boshlandi",
    visit_time: "Tashrif vaqti",
    visits: "Tashriflar",
    waiting: "Kutmoqda",
    waiting_list: "Kutish varaqasi",
    with_formula: "Formula bilan",
    without_payment: "To‘lov yo‘q",
    write_reason: "Sababni yozing",
    xray: "Rentgen",
    years: "Yosh",
    you_need_to_select_teeth: "Siz tishni tanlashingiz kerak",
    zipcode: "Pochta indeksi",
}
