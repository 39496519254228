<template>
  <section id="settings" class="settings">
    <div class="section-top-row">
      <h1>{{ $t('settings') }}</h1>
    </div>
    <div class="sub-menu">
      <template v-for="(m, mind) in menu">
        <router-link :to="{ name: m.to }"
                     v-if="!m.right || rights.includes(m.right)"
                     :key="`settings-link-${mind}}`">
          {{ $t(m.title) }}
        </router-link>
      </template>
    </div>
    <router-view/>
  </section>
</template>

<script>
import { mapState } from "vuex"

export default {
  name: "SettingsGeneral",
  data() {
    return {
      menu: [
        { to: 'SettingsCalendar', title: 'calendar' },
        { to: 'SettingsClinic', title: 'settings' },
        { to: 'SettingsContacts', title: 'contacts' },
        { to: 'SettingsTags', title: 'tags' },
        { to: 'SettingsRecordTags', title: 'record_tags' },
        { to: 'SettingsPatientConditions', title: 'patient_conditions', right: 'patient_conditions' },
        { to: 'SettingsIntegrations', title: 'integrations', right: 'sms_send', },
        { to: 'SettingsCurrencies', title: 'currency_rates', right: 'clinic_currencies' },
        { to: 'SettingsSources', title: 'patient_sources' },
        { to: 'SettingsPayMethods', title: 'pay_methods' },
        { to: 'SettingsDepositAccounts', title: 'deposit_accounts' },
        { to: 'SettingsRecordReasons', title: 'cancel_and_move_reasons' },
        { to: 'SettingsFields', title: 'fields' },
        { to: 'SettingsBonuses', title: 'bonus_system', right: 'bonuses' },
        { to: 'SettingsTemplates', title: 'templates' },
        { to: 'SettingsControlSystem', title: 'control_system', right: 'control_system' },
        { to: 'SettingsEGISZ', title: 'egisz', right: 'egisz_view' },
        { to: 'SettingsOnlineBooking', title: 'online_booking', right: 'online_booking' },
      ]
    }
  },
  computed: {
    ...mapState({
      rights: state => state.auth.rights,
    }),
  }
}
</script>
