export default {
    insurance_companies: "Страховые компании",
    insurance_polis: "Страховой полис",
    limit: "Лимит",
    number: "Номер",
    polis: "Полис",
    polises: "Полисы",
    use_custom_prices_for_insurance: "Использовать индивидуальные цены для страховых компаний",
    valid_from: "Действителен с",
    valid_to: "Действителен до",
}
