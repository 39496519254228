export default {
    primaryKey: 'id',
    model: 'supplier',
    order: 'id',
    sort: 'asc',
    actions: [
        "add",
        "excel",
        "edit",
        "delete"
    ],
    deletemessage: 'are_you_sure_you_want_to_delete_supplier',
    showTotalRecords: true,
    fields: [
        {
            id: 'id',
            title: 'id',
            table: true,
            sortable: true
        },
        {
            id: 'title',
            title: 'title',
            table: true,
            tabletdclass: "order-mob-0",
            sortable: true,
            filter: true,
            form: {
                type: "text",
                required: true
            }
        },
        {
            id: 'bin',
            title: 'bin',
            table: true,
            sortable: true,
            filter: true,
            form: {
                type: "text",
                required: true
            }
        },
        {
            id: 'phone',
            title: 'phone',
            table: true,
            sortable: true,
            filter: true,
            form: {
                type: "text",
                required: true
            },
            format_phone: true,
        },
        {
            id: 'email',
            title: 'email',
            table: true,
            sortable: true,
            filter: true,
            form: {
                type: "text",
                required: true
            },
            format_email: true,
        },
        {
            id: 'address',
            title: 'address',
            table: false,
            form: {
                type: "text",
                required: true
            }
        },
        {
            id: 'contact_person',
            title: 'contact_person',
            table: true,
            sortable: true,
            form: {
                type: "text"
            }
        },
        {
            id: 'bank_requisites',
            title: 'bank_requisites',
            table: false,
            form: {
                type: "textarea"
            }
        }
    ]
}
