<template>
  <div class="pb-4" v-if="month">
    <div class="mb-3">
      <a class="btn btn-themed btn-themed-outline btn-themed-squared"
         v-if="month.status == 1"
         @click="closeMonth">
        {{ $t('recalculate') }}
      </a>
      <a class="btn btn-themed btn-themed-outline btn-themed-squared ml-3"
         v-if="month.status == 1"
         @click="openMonth">
        {{ $t('open') }}
      </a>
      <!--      <div v-if="month.status == 1"-->
      <!--           class="mb-3 mt-2 d-inline-block text-primary font-weight-bold">-->
      <!--        {{ $t('recalculate') }}-->
      <!--      </div>-->
      <a  v-if="month.status == 0" class="btn btn-themed btn-themed-outline btn-themed-squared"
          @click="editMonth">
        {{ $t('edit') }}
      </a>
      <a class="btn btn-themed btn-themed-outline btn-themed-squared ml-3"
         v-if="month.status == 0 && month.date < now"
         @click="closeMonth">
        {{ $t('close') }}
      </a>
    </div>
    <h5>{{ $t('accruals') }}</h5>
    <b-row v-if="month">
      <b-col md="auto" cols="12">
        <div class="month-summary-table">
          <div v-for="(c, cind) in columns_accrual" :key="`column-accrual-${cind}`">
            <div class="t-row">
              <div class="title">{{ $t(c.title) }}</div>
              <div class="value" :class="{ 'text-danger': month[c.id] < 0 }">{{ formatNumber(month[c.id]) }}</div>
            </div>
          </div>
        </div>
      </b-col>
      <b-col md cols="12" class="mt-md-0 mt-2">
        <div class="pl-md-4">
          <div class="total" :class="{ negative: month.total < 0 }">
            <div>
              <small>{{ $t('total') }}</small>
            </div>
            <span>{{ formatCurrency(month.total) }}</span>
          </div>
        </div>
      </b-col>
    </b-row>



    <h5 class="mt-5">{{ $t('cash_movements') }}</h5>
    <b-row v-if="month">
      <b-col md="auto" cols="12">
        <div class="month-summary-table">
          <div v-for="(c, cind) in columns_cash" :key="`column-cash-${cind}`">
            <div class="t-row">
              <div class="title">{{ $t(c.title) }}</div>
              <div class="value" :class="{ 'text-danger': month[c.id] < 0 }">{{ formatNumber(month[c.id]) }}</div>
            </div>
          </div>
        </div>
      </b-col>
      <b-col md cols="12" class="mt-md-0 mt-2">
        <div class="pl-md-4">
          <div class="total" :class="{ negative: cash_total < 0 }">
            <div>
              <small>{{ $t('total') }}</small>
            </div>
            <span>{{ formatCurrency(cash_total) }}</span>
          </div>
        </div>
      </b-col>
    </b-row>


    <h5 class="mt-5">{{ $t('deposit_movement') }}</h5>
    <b-row v-if="month">
      <b-col md="auto" cols="12">
        <div class="month-summary-table">
          <div v-for="(c, cind) in columns_deposit" :key="`column-deposit-${cind}`">
            <div class="t-row">
              <div class="title">{{ $t(c.title) }}</div>
              <div class="value" :class="{ 'text-danger': month[c.id] < 0 }">{{ formatNumber(month[c.id]) }}</div>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
    <div v-if="month && usesBonuses">
      <h5 class="mt-5">{{ $t('bonus_movement') }}</h5>
      <b-row>
        <b-col md="auto" cols="12">
          <div class="month-summary-table">
            <div v-for="(c, cind) in columns_bonus" :key="`column-bonus-${cind}`">
              <div class="t-row">
                <div class="title">{{ $t(c.title) }}</div>
                <div class="value" :class="{ 'text-danger': month[c.id] < 0 }">{{ formatNumber(month[c.id]) }}</div>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
    </div>


  </div>
</template>

<script>

import {mapGetters, mapState} from "vuex"
import {AccountingService} from "@/services/api.service"
import moment from "moment/moment"
import {formatCurrency, formatNumber} from "@/extensions/filters/filters"

export default {
  name: "AccountingMonthSummary",
  head() {
    return {
      title: this.$t('accounting'),
    }
  },
  computed: {
    ...mapState({
      month: state => state.accounting.accountingmonth,
    }),
    ...mapGetters(['usesBonuses']),
    cash_total() {
      if(!this.month) return 0
      let sum = 0
      this.columns_cash.forEach(item => {
        sum += parseFloat(this.month[item.id])
      })
      return sum
    }
  },
  data() {
    return {
      columns_accrual: [
        { id: 'revenue', title: 'revenue' },
        { id: 'materials_expense', title: 'materials_expense' },
        { id: 'salaries', title: 'salaries' },
        { id: 'other_income', title: 'other_income' },
        { id: 'other_expenses', title: 'other_expense' },
        { id: 'rent_signed', title: 'rent' },
        { id: 'communal_signed', title: 'communal' },
        { id: 'acquiring_fee', title: 'acquiring_fee' },
      ],
      columns_cash: [
        { id: 'cash_revenue', title: 'revenue' },
        { id: 'deposit_netto', title: 'deposit_movement' },
        { id: 'all_material_expense', title: 'materials_expense' },
        { id: 'all_salaries_expense', title: 'salaries' },
        { id: 'other_income', title: 'other_income' },
        { id: 'other_expenses', title: 'other_expense' },
        { id: 'rent_signed', title: 'rent' },
        { id: 'communal_signed', title: 'communal' },
        { id: 'acquiring_fee', title: 'acquiring_fee' },
      ],
      columns_deposit: [
        { id: 'deposit_balance_start', title: 'deposit_balance_start' },
        { id: 'deposit_additions', title: 'balance_refill' },
        { id: 'deposit_withdrawals', title: 'balance_return' },
        { id: 'deposit_payments', title: 'balance_amount' },
        { id: 'deposit_balance_end', title: 'deposit_balance_end' },
      ],
      columns_bonus: [
        { id: 'bonus_balance_start', title: 'balance_start' },
        { id: 'bonus_accruals', title: 'accruals' },
        { id: 'bonus_payments', title: 'payments' },
        { id: 'bonus_adjustments', title: 'manual_adjustments' },
        { id: 'bonus_balance_end', title: 'balance_end' },
      ],
      now: moment().format('YYYY-MM-DD')
    }
  },
  methods: {
    formatNumber,
    formatCurrency,
    editMonth() {
      let newObject = JSON.parse(JSON.stringify(this.month))
      this.$store.commit('setViewModel', 'accountingmonth')
      this.$store.commit('setModule', 'accounting')
      this.$store.commit('setSelectedModel', newObject)
      this.$store.commit('setShowModal', true)
    },
    async closeMonth() {
      try {
        const res = await AccountingService.closeMonth(this.month.id)
        if(res.data) {
          this.month.status = 1
          this.$noty.info(this.$t('success_message'))
          this.$store.commit('incrementDataTableCounter')
        }
        this.$store.commit('setAccountingMonth', this.month)
      } catch (e) {
        console.log(e)
      }
    },
    async openMonth() {
      let res = await AccountingService.openMonth(this.month.id)
      if(res.data) {
        this.month.status = 0
        this.$noty.info(this.$t('success_message'))
        this.$store.commit('incrementDataTableCounter')
      }
      this.$store.commit('setAccountingMonth', this.month)
    }
  }
}
</script>

<style scoped lang="scss">
.month-summary-table {
  width: 405px;
  .t-row {
    display: flex;
    .title {
      order: 1;
    }
    .value {
      order: 3;
    }
    &:after {
      background-image: radial-gradient(circle, currentcolor 1px, transparent 1.5px);
      background-position: bottom;
      background-size: 1ex 4.5px;
      background-repeat: space no-repeat;
      content: "";
      flex-grow: 1;
      height: 1.2em;
      color: $gray-text;
      order: 2;
    }
  }
}
.total {
  display: inline-block;
  padding: 8px 30px 8px 12px;
  color: #fff;
  font-size: 16px;
  background: $green-color;
  &.negative {
    background: $danger-red;
  }
  > span {
    font-size: 16px;
    font-weight: bold;
  }
}

@media screen and (max-width: 768px) {
  .month-summary-table {
    width: 100%;
  }
  .total {
    padding: 4px 20px 4px 10px;
    font-size: 12px;
    > span {
      font-size: 14px;
    }
  }
}

</style>
