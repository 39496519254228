export const recordTeethAdapter = (app) => ({
    teeth_medication: app.object.teeth_medication,
    teeth_recommendations: app.object.teeth_recommendations,
    teeth_plansurvey: app.object.teeth_plansurvey,
    packages: app.packages,
    materials: app.materials,
    services: app.services,
    complexServices: app.complexServices,
    diagnoses: minimizeDiagnoses(app.diagnoses),
    fields: app.object.fields?.id ? [app.object.fields.id] : [],
    id: app.object.id,
    client_id: app.object.client_id,
    start: app.object.start,
    end: app.object.end,
    doctor_id: app.object.doctor_id,
    assistant_id: app.object.assistant_id,
    template_id: app.object.template_id,
    visit_id: app.object.visit_id,
    complaints: app.object.complaints,
    bite: app.object.bite,
    inspection: app.object.inspection,
    objectively: app.object.objectively,
    subjectively: app.object.subjectively,
    diseases: app.object.diseases,
    development: app.object.development,
    // life_anamnez: app.object.life_anamnez,
    research: app.object.research,
    description: app.object.description,
    records_statuses_id: app.object.records_statuses_id,
    planned_services: minimizePlanned(app.object.planned_services),
    customFields: app.object.customFields,
    f043: app.object.f043,
    selectedTeeth: app.teeth,
    changedServices: app.versionCount > 0,
    teethDescriptions: app.teethDescriptions ? app.teethDescriptions : null,
    record_additional_info: app.record_additional_info || {}
})

function minimizeDiagnoses(diagnoses = []) {
    let data = {}
    for(const id in diagnoses) {
        data[id] = {
            id: diagnoses[id].id,
            teeth: diagnoses[id].teeth,
        }
    }
    return data
}

function minimizePlanned(services = []) {
    let data = {}
    for(const id in services) {
        if(services[id]) {
            data[id] = {
                id: services[id].id,
            }
        }
    }
    return data
}
