<template>
  <div v-if="canBeShown">
    <a class="btn-themed btn-themed-outline btn-themed-squared d-block text-center"
       @click="startVisit">
      {{ closedVisit ? $t('reopen_visit') : $t('start_visit') }}
    </a>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex"
import { CalendarService } from "@/services/api.service"

export default {
  name: "StartVisitTiming",
  computed: {
    ...mapState({
      visit: state => state.patient.visit,
      myOpenedVisit: state => state.treatment.myOpenedVisit,
      rights: state => state.auth.rights,
    }),
    ...mapGetters([
      'isDoctor',
      'recordTimingIsOn'
    ]),
    canBeShown() {
      if(!this.rights.includes('control_system')) return false
      if(!this.isDoctor) return false
      if(!this.recordTimingIsOn) return false
      if(this.visit.closed_visit && !this.rights.includes('repeat_open_visit')) return false
      if(this.myOpenedVisit) return false
      return true
    },
    closedVisit() {
      return !!this.visit.closed_visit?.id
    }
  },
  methods: {
    async startVisit() {
      const res = await CalendarService.setRecordTiming(this.visit.id, {
        action: 'open'
      })
      if(res.data?.id) {
        this.$store.commit('setMyOpenedVisit', res.data)
      }
      this.$emit('reloadVisit')
    }
  }
}
</script>
