<template>
  <div class="mt-3 pb-5" v-if="rows">

    <DayReportCharts :charts="rows.charts"/>

    <b-row>
      <b-col md="4" cols="12">
        <div class="b-block" @click="detailShow('invoiced')">
          <div class="b-label">{{ $t('invoiced') }}</div>
          <div class="b-value">{{ formatCurrency(rows.revenue) }}</div>
        </div>
      </b-col>
      <b-col md="4" cols="12" class="mt-md-0 mt-3">
        <div class="b-block" @click="detailShow('paid')">
          <div class="b-label">{{ $t('paid_invoices') }}</div>
          <div class="b-value">{{ formatCurrency(rows.cash_day_in_day) }}</div>
        </div>
      </b-col>
      <b-col md="4" cols="12" class="mt-md-0 mt-3">
        <div class="b-block" @click="detailShow('unpaid')">
          <div class="b-label">{{ $t('unpaid_invoices') }}</div>
          <div class="b-value">{{ formatCurrency(rows.revenue - rows.cash_day_in_day) }}</div>
        </div>
      </b-col>
    </b-row>

    <b-row class="mt-4">
      <b-col cols="12" md="4" lg="3" class="mt-3 mt-md-0">
        <div class="card">
          <h2 class="sub-label">{{ $t('revenues') }}</h2>
          <div>
            <table class="sub-items">
              <tbody>
              <tr>
                <td>{{ $t('paid_invoices') }}</td>
                <td>{{ formatNumber(rows.cash_day_in_day, true) }}</td>
              </tr>
              <tr>
                <td>{{ $t('debt_payments') }}</td>
                <td>{{ formatNumber((totalCashIn - rows.cash_day_in_day), true) }}</td>
              </tr>
              <tr>
                <td>{{ $t('additional_income') }}</td>
                <td>{{ formatNumber(totalOtherProfit, true) }}</td>
              </tr>
              </tbody>
              <tfoot>
              <tr>
                <td>{{ $t('total') }}</td>
                <td>{{ formatNumber(totalIncome, true) }}</td>
              </tr>
              </tfoot>
            </table>
          </div>



          <div class="mt-4">
            <h2 class="sub-label">{{ $t('by_means') }}</h2>
            <table class="sub-items">
              <tbody>
              <tr v-for="(sum, forma)  in rows.cash_in" :key="`profit-key-${forma}`">
                <td>{{ $t(forma) }}</td>
                <td>{{ formatNumber((parseFloat(sum) + parseFloat((rows.other_profit[forma] ? rows.other_profit[forma] : 0))), true) }}</td>
              </tr>
              </tbody>
            </table>
          </div>

        </div>
      </b-col>


      <b-col cols="12" md="4" lg="3" class="mt-3 mt-md-0">
        <div class="card">
          <h2 class="sub-label">{{ $t('deposit') }}</h2>
          <table class="sub-items">
            <tbody>
            <tr>
              <td>{{ $t('balance_refill') }}</td>
              <td>{{ formatNumber(rows.deposit_fills, true) }}</td>
            </tr>
            <tr>
              <td>{{ $t('balance_return') }}</td>
              <td>{{ formatNumber(rows.deposit_writeoffs, true) }}</td>
            </tr>
            </tbody>
            <tfoot>
            <tr>
              <td>{{ $t('total') }}</td>
              <td>{{ formatNumber((rows.deposit_fills - rows.deposit_writeoffs), true) }}</td>
            </tr>
            </tfoot>
          </table>


          <div class="mt-4" v-if="Object.values(rows.deposit_fills_detailed).length">
            <h2 class="sub-label">{{ $t('by_means') }}</h2>
            <table class="sub-items">
              <tbody>
              <tr v-for="(sum, forma)  in rows.deposit_fills_detailed" :key="`deposit-key-${forma}`">
                <td>{{ $t(forma) }}</td>
                <td>{{ formatNumber(parseFloat(sum), true) }}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>

      </b-col>


      <b-col cols="12" md="4" lg="3" class="mt-3 mt-md-0">
        <div class="card">
          <h2 class="sub-label">{{ $t('costs') }}</h2>
          <table class="sub-items">
            <tbody>
            <tr>
              <td>{{ $t('materials') }}</td>
              <td>{{ formatNumber(rows.materials, true) }}</td>
            </tr>
            <tr>
              <td>{{ $t('salaries') }}</td>
              <td>{{ formatNumber(totalSalaries, true) }}</td>
            </tr>
            <tr>
              <td>{{ $t('debt_writeoff') }}</td>
              <td>{{ formatNumber(rows.debt_cancellation, true) }}</td>
            </tr>
            <tr>
              <td>{{ $t('other_expense') }}</td>
              <td>{{ formatNumber(rows.other_expense, true) }}</td>
            </tr>
            </tbody>
            <tfoot>
            <tr>
              <td>{{ $t('total') }}</td>
              <td>{{ formatNumber(totalCosts, true) }}</td>
            </tr>
            </tfoot>
          </table>
        </div>
      </b-col>



      <b-col cols="12" md="4" lg="3" class="mt-3 mt-md-0">
        <div class="card">
          <h2 class="sub-label">{{ $t('saldo') }}</h2>
          <table class="sub-items">
            <tbody>
            <tr>
              <td>{{ $t('profit') }}</td>
              <td>{{ formatNumber(totalIncome, true) }}</td>
            </tr>
            <tr>
              <td>{{ $t('expense') }}</td>
              <td>{{ formatNumber(totalCosts, true) }}</td>
            </tr>
            </tbody>
            <tfoot>
            <tr>
              <td>{{ $t('total') }}</td>
              <td>{{ formatNumber((totalIncome - totalCosts), true) }}</td>
            </tr>
            </tfoot>
          </table>
        </div>
      </b-col>
    </b-row>

    <hr class="mt-5 mb-5">

    <b-row>
      <b-col cols="12" md="4" lg="3" class="mt-3 mt-md-0">
        <div class="card">
          <h2 class="sub-label">{{ $t('cash_received') }}
            <span class="text-lowercase">({{ $t('deposit') }} + {{ $t('profit') }})</span>
          </h2>
          <div>
            <table class="sub-items">
              <tbody>
              <template v-for="(sum, forma)  in rows.cash_in">
                <tr :key="`profit-key-${forma}`" v-if="!['balance', 'family_account', 'debt_cancellation', 'warranty_case', 'bonuses'].includes(forma)">
                  <td>{{ $t(forma) }}</td>
                  <td>
                    {{ formatNumber((parseFloat(sum) + parseFloat((rows.other_profit[forma] ? rows.other_profit[forma] : 0)) + parseFloat((rows.deposit_fills_detailed[forma] ? rows.deposit_fills_detailed[forma] : 0))), true) }}
                  </td>
                </tr>
              </template>
              </tbody>
              <tfoot>
              <tr>
                <td>{{ $t('total') }}</td>
                <td>{{ formatNumber(totalCashMovement, true) }}</td>
              </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </b-col>
    </b-row>

    <ReportDayVisitDetails :visits="visits"
                           :detailsType="detailsType"/>
  </div>
</template>

<script>
import { ReportsService } from "@/services/api.service"
import ReportDayVisitDetails from "@/components/pages/reports/ReportDayVisitDetails"
import { mapState } from "vuex"
import DayReportCharts from "@/components/parts/reports/DayReportCharts.vue"
import { formatNumber, formatCurrency } from "@/extensions/filters/filters"

export default {
  name: "ReportDay",
  components: {
    DayReportCharts,
    ReportDayVisitDetails
  },
  props: {
    from: {},
    to: {},
    doctors: {
      type: Array
    },
    selectedBranches: {}
  },
  head() {
    return {
      title: this.$t('daily_report'),
    }
  },
  data() {
    return {
      columns: [],
      rows: null,
      visits: [],
      detailsType: ''
    }
  },
  computed: {
    ...mapState({
      clinic: state => state.auth.clinic
    }),
    totalCashIn() {
      if(!this.rows) return 0
      return Object.values(this.rows.cash_in).reduce((a, b) => a + parseFloat(b))
    },
    totalOtherProfit() {
      if(!this.rows) return 0
      return Object.values(this.rows.other_profit).reduce((a, b) => a + parseFloat(b))
    },
    totalIncome() {
      if(!this.rows) return 0
      return this.totalCashIn + parseFloat(this.totalOtherProfit)
    },
    totalSalaries() {
      if(!this.rows) return 0
      return parseFloat(this.rows.doctor_salaries) + parseFloat(this.rows.technic_salaries) + parseFloat(this.rows.manager_salaries) + parseFloat(this.rows.assistant_salaries)
    },
    totalCosts() {
      if(!this.rows) return 0
      return parseFloat(this.totalSalaries) + parseFloat(this.rows.materials) + parseFloat(this.rows.other_expense) + parseFloat(this.rows.debt_cancellation)
    },
    totalCashMovement() {
      if(!this.rows) return 0
      let sum = 0
      for (const [key, value] of Object.entries(this.rows.cash_in)) {
        if(!['balance', 'family_account', 'debt_cancellation', 'warranty_case', 'bonuses'].includes(key)) {
          const other = parseFloat((this.rows.other_profit[key] ? this.rows.other_profit[key] : 0))
          const deposit = parseFloat((this.rows.deposit_fills_detailed[key] ? this.rows.deposit_fills_detailed[key] : 0))
          sum += (parseFloat(value) + other + deposit)
        }
      }
      return sum
    },
    ...mapState({
      branchId: state => state.auth.branch,
    })
  },
  methods: {
    formatNumber,
    formatCurrency,
    loadReport() {
      ReportsService.loadReport('daily_report', {
        from: this.from,
        to: this.to,
        branch_id: this.selectedBranches,
      }).then(res => {
        this.rows = res.data
      })
    },
    async detailShow(type) {
      let res = await ReportsService.getCashDetailsByVisit(type, this.from, this.to)
      this.visits = res.data
      this.detailsType = type
      this.$bvModal.show('visit-details-modal')
    },
    excel() {
    }
  },
  mounted() {
    this.loadReport()
  }
}
</script>

<style scoped lang="scss">
.b-block {
  padding: 10px 20px;
  background: #eee;
  cursor: pointer;
  .b-label {
    font-size: 12px;
  }
  .b-value {
    font-size: 18px;
  }
  &:hover {
    background: #ddd;
  }
}
.sub-label {
  font-size: 18px;
  font-weight: bold;
}
.sub-items {
  width: 100%;
  tfoot {
    td {
      font-weight: bold;
    }
    tr {
      &:last-of-type {
        border-top: 1px solid $calendar-border-color;
        border-bottom: none;
      }
    }
  }
  tbody, tfoot {
    td:last-of-type {
      text-align: right;
    }
  }
  tr {
    border-bottom: 1px solid $calendar-border-color;
  }
  tbody {
    tr {
      &:last-of-type {
        border-bottom: none;
      }
    }
  }
  td {
    padding: 5px 3px;
  }
}
.card {
  padding: 16px;
  border: none;
  background: #FFFFFF;
  box-shadow: 0 1px 10px -3px rgba(78, 78, 78, 0.25);
  border-radius: 5px;
}

@media screen and (max-width: 768px) {
  .b-block {
    padding: 8px 16px;
    .b-label {
      font-size: 12px;
    }
    .b-value {
      font-size: 16px;
    }
  }
  .sub-label {
    font-size: 15px;
  }
}
</style>
