<template>
  <div class="mt-3">
    <DataTableHtml ref="datatable"
                   :columns="columns"
                   initialOrder="date"
                   initialSort="desc"
                   :transferToExcel="transferToExcel"
                   :excel-columns-adjustment="excelColumnsAdjustment"
                   :rows="rows"/>
  </div>
</template>

<script>
import { ReportsService } from "@/services/api.service"
import DataTableHtml from "@/components/parts/general/DataTableHtml"

export default {
  name: "ReportsVisits",
  components: {
    DataTableHtml
  },
  props: {
    from: {},
    to: {},
    doctors: {
      type: Array
    },
    patients: {
      type: Array
    },
    selectedBranches: {}
  },
  head() {
    return {
      title: this.$t('visits'),
    }
  },
  data() {
    return {
      columns: [
        {
          id: 'date',
          field: 'date',
          label: this.$t('date'),
          sortable: true,
          format_datetime: true
        },
        {
          id: 'patient_name',
          field: 'patient_name',
          label: this.$t('patient'),
          tdcomponent: 'PatientsDatatableTDName',
          search_select: true,
          sortable: true
        },
        {
          id: 'doctor_name',
          field: 'doctor_name',
          label: this.$t('doctor'),
          search_select: true,
          sortable: true
        },
        {
          id: 'curator',
          field: 'curator',
          label: this.$t('curator'),
          search_select: true,
          sortable: true
        },
        {
          id: 'duration',
          field: 'duration',
          label: this.$t('duration'),
          sortable: true
        },
        {
          id: 'diagnoses',
          field: 'diagnoses',
          label: this.$t('diagnoses'),
          format_html: true,
          sortable: true
        },
        {
          id: 'services',
          field: 'services',
          label: this.$t('services'),
          format_html: true,
          sortable: true
        },
        {
          id: 'materials',
          field: 'materials',
          label: this.$t('cost_of_materials'),
          sortable: true,
          format_number: true
        },
        {
          id: 'price_before_discount',
          field: 'price_before_discount',
          label: this.$t('price_before_discount'),
          sortable: true,
          format_number: true,
          totals: true
        },
        {
          id: 'discount',
          field: 'discount',
          label: this.$t('discount'),
          sortable: true,
          format_number: true,
          totals: true
        },
        {
          id: 'price',
          field: 'price',
          label: this.$t('sum_with_discount'),
          sortable: true,
          format_number: true,
          totals: true
        },
        {
          id: 'paid',
          field: 'paid',
          label: this.$t('paid'),
          sortable: true,
          format_number: true,
          totals: true
        },
        {
          id: 'debt',
          field: 'debt',
          label: this.$t('debt'),
          sortable: true,
          format_number: true,
          totals: true
        },
        {
          id: 'author_name',
          field: 'author_name',
          label: this.$t('who_created'),
          search_select: true,
          sortable: true,
        },
      ],
      rows: []
    }
  },
  computed: {
    rights() {
      return this.$store.state.auth.rights
    }
  },
  methods: {
    loadReport() {
      ReportsService.loadReport('visits', {
        from: this.from,
        to: this.to,
        doctors: this.doctors.map(a => a.id),
        patients: this.patients.map(a => a.id),
        branch_id: this.selectedBranches,
      }).then(res => {
        this.rows = res.data
      })
    },
    excel() {
      this.$refs.datatable.excel()
    },
    excelColumnsAdjustment(columns)  {
      columns.unshift({
        id: 'visit_id',
        field: 'visit_id',
        label: this.$t('visit_id'),
      })
      columns.splice(2, 0, {
        id: 'patient_id',
        field: 'patient_id',
        label: this.$t('patient_id'),
      })
      columns.splice(5, 0, {
        id: 'curator_id',
        field: 'curator_id',
        label: this.$t('curator_id'),
      })
      return columns
    },
    transferToExcel(data) {
      let excelRows = []
      if(data) {
        data.forEach(item => {
          if(item.egisz) item.egisz = this.$t(item.egisz)
          excelRows.push(item)
        })
      }
      return excelRows
    },
  },
  mounted() {
    if(this.rights.includes('egisz_view')) {
      this.columns.push({
        id: 'egisz',
        field: 'egisz',
        label: this.$t('egisz'),
        format_translate: true,
        search_select: true,
        sortable: true
      })
    }
    this.loadReport()
  }
}
</script>
