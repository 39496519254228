<template>
  <div v-if="views.length > 1">
    <b-row class="switchers-row">
      <b-col v-for="v in views"
             cols="auto"
             :key="`v-${v.id}`">
        <a @click="viewSelected(v)"
           class="pointer text-decoration-none switch-selector"
           :class="{ active: scheduleView === v.id }">
          <component :is="v.icon"
                     class="mr-2 svg-icon"/>
          {{ $t(v.id) }}
        </a>
      </b-col>
    </b-row>
    <hr class="mt-0"/>
  </div>
</template>

<script setup>
import { useStore } from "vuex"
import { computed } from "vue"
import DoctorSvg from "@/assets/svg-vue/staff/doctor.svg"
import AdministratorSvg from "@/assets/svg-vue/staff/administrator.svg"
import AssistantSvg from "@/assets/svg-vue/staff/assistant.svg"
import { ViewType } from "@/components/pages/calendar/calendar/const/CalendarConst"

const views = computed(() => {
  const arr = [{ id: 'doctors', icon: DoctorSvg }]
  arr.push({ id: 'administrators', icon: AdministratorSvg, right: 'administrator_schedule' })
  arr.push({ id: 'assistants', icon: AssistantSvg, right: 'assistant_schedule' })
  return arr.filter(item => item.right ? rights.value.includes(item.right) : true)
})

const store = useStore()
const scheduleView = computed(() => store.state.calendar.scheduleView)
const activeRange = computed(() => store.getters.activeRange)
const rights = computed(() => store.state.auth.rights)

const viewSelected = async (view) => {
  store.commit('setScheduleView', view.id)
  // await nextTick()
  store.commit('setCurRange', activeRange.value)
  // await nextTick()
  if(view.id !== 'doctors') {
    // await store.dispatch('getStaffSchedule', {
    //   user_group: view.id
    // })
    store.commit('setCurView', ViewType.DOCTOR)
  } else {
    store.commit('setLoading', true)
  }
  // store.commit('setRefreshCalendar', true)
}
</script>

<style scoped lang="scss">
.switch-selector {
  color: #5D6D7C;
  font-size: 16px;
  font-weight: 700;
  padding: 10px 0;
  display: inline-block;
  &.active, &:hover {
    color: $blue-color;
    border-bottom: 2px solid $blue-color;
    .svg-icon {
      fill: $blue-color;
    }
  }
  .svg-icon {
    fill: $gray-icon;
  }
}
.switchers-row {
  white-space: nowrap;
  overflow-x: auto;
  flex-wrap: nowrap;
}
</style>
