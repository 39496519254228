<template>
  <div class="form-group">
    <label v-if="label" class="form-label" :data-content="label">
      <span>{{ label }}</span>
      <span v-if="required"> *</span>
    </label>
    <b-row>
      <b-col md="3" v-for="c in currencies" :key="`cur-${c}`">
        <label class="text-uppercase form-label small">{{ c }}</label>
        <input type="number"
               step="0.1"
               class="form-control"
               @input="(...args) => handleInput(c, ...args)"
               @keyup="$emit('keyup', $event)"
               @keyup.enter="$emit('enter')"
               :readonly="readonly ? readonly : false"
               :value="value ? value[c] : ''"/>
      </b-col>
    </b-row>


  </div>
</template>

<script>

import { currencyForJson } from "@/dictionaries/dictionary"

export default {
  name: "CurrencyJsonGroup",
  data() {
    return {
      content: this.value,
      currencies: currencyForJson
    }
  },
  props: {
    label: {},
    value: {},
    readonly: {},
    placeholder: String,
    required: {
      type: Boolean,
      default: false
    }
  },
  mounted() {
  },
  methods: {
    handleInput(c, e) {
      let obj = this.copyObject(this.value)
      obj[c] = e.target.value
      this.$emit('input', obj)
    }
  },
  watch: {
    value: {
      handler(val) {
        if(!val) {
          const obj = this.currencies.reduce((o, key) => ({ ...o, [key]: 0}), {})
          this.$emit('input', obj)
        }
      },
      immediate: true
    }
  }
}
</script>

<style scoped lang="scss">

</style>
