<template>
  <div class="mt-3">
    <DataTableHtml ref="datatable"
                   :columns="columns"
                   initialOrder="date"
                   initialSort="desc"
                   :excel-columns-adjustment="excelColumnsAdjustment"
                   :rows="rows"/>
  </div>
</template>

<script>

import { ReportsService } from "@/services/api.service"
import DataTableHtml from "@/components/parts/general/DataTableHtml"

export default {
  name: "ReportsServices",
  components: {
    DataTableHtml
  },
  props: {
    from: {},
    to: {},
    doctors: Array,
    patients:  Array,
    services: Array,
    categories: Array,
    selectedBranches: {}
  },
  head() {
    return {
      title: this.$t('services'),
    }
  },
  data() {
    return {
      columns: [
        {
          id: 'record_id',
          field: 'record_id',
          label: this.$t('visit_id'),
          sortable: true,
        },
        {
          id: 'date',
          field: 'date',
          label: this.$t('date'),
          sortable: true,
          format_datetime: true
        },
        {
          id: 'patient_name',
          field: 'patient_name',
          label: this.$t('patient'),
          search_select: true,
          sortable: true
        },
        {
          id: 'doctor_name',
          field: 'doctor_name',
          label: this.$t('doctor'),
          search_select: true,
          sortable: true
        },
        {
          id: 'category',
          field: 'category',
          label: this.$t('category'),
          search_select: true,
          sortable: true
        },
        {
          id: 'service',
          field: 'service',
          label: this.$t('service'),
          search_select: true,
          sortable: true
        },
        {
          id: 'service_code',
          field: 'service_code',
          label: this.$t('service_code'),
          sortable: true
        },
        {
          id: 'pricelist_price',
          field: 'pricelist_price',
          label: this.$t('pricelist_price'),
          format_number: true,
          sortable: true
        },
        {
          id: 'discounted_price',
          field: 'discounted_price',
          label: this.$t('discounted_price'),
          format_number: true,
          sortable: true
        },
        {
          id: 'average_price',
          field: 'average_price',
          label: this.$t('average_price'),
          format_number: true,
          sortable: true
        },
        {
          id: 'quantity',
          field: 'quantity',
          label: this.$t('amount'),
          format_number: true,
          sortable: true,
          totals: true
        },
        {
          id: 'sum',
          field: 'sum',
          label: this.$t('sum'),
          format_number: true,
          sortable: true,
          totals: true
        },
        {
          id: 'money_lost_on_discount',
          field: 'money_lost_on_discount',
          label: this.$t('money_lost_on_discount'),
          format_number: true,
          sortable: true,
          totals: true
        },
        // {
        //   id: 'doctor_salary',
        //   field: 'doctor_salary',
        //   label: this.$t('doctor_salary'),
        //   format_number: true,
        //   sortable: true,
        //   totals: true
        // }
      ],
      rows: []
    }
  },
  methods: {
    loadReport() {
      ReportsService.loadReport('services', {
        from: this.from,
        to: this.to,
        doctors: this.doctors.map(a => a.id),
        patients: this.patients.map(a => a.id),
        services: this.services.map(a => a.id),
        categories: this.categories.map(a => a.id),
        branch_id: this.selectedBranches,
      }).then(res => {
        this.rows = res.data
      })
    },
    excelColumnsAdjustment(columns)  {
      columns.splice(2, 0, {
        id: 'patient_id',
        field: 'patient_id',
        label: this.$t('patient_id'),
      })
      columns.push({
        id: 'curator_id',
        field: 'curator_id',
        label: this.$t('curator_id'),
      })
      columns.push({
        id: 'curator_name',
        field: 'curator_name',
        label: this.$t('curator_name'),
      })
      return columns
    },
    excel() {
      this.$refs.datatable.excel()
    }
  },
  mounted() {
    this.loadReport()
  }
}
</script>
