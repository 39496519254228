export default {
    insurance_companies: "Страхові компанії",
    insurance_polis: "Страховий поліс",
    limit: "Ліміт",
    number: "Номер",
    polis: "Поліс",
    polises: "Поліси",
    use_custom_prices_for_insurance: "Використовувати індивідуальні ціни для страхових компаній",
    valid_from: "Дійсний з",
    valid_to: "Дійсний до",
}
