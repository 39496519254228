<template>
  <b-modal id="patient-print-modal" :title="$t('print_noun')" centered>
    <div>{{ $t('choose_document_type_and_its_parameters') }}:</div>
    <div class="mt-4">
      <RadioGroup :options="documentTypes"
                  v-model="documentType"
                  validationId="documentType"/>
    </div>
    <b-row class="mt-4">
      <b-col md="6" cols="12">
        <DateGroup :label="$t('date_from')"
                   :required="true"
                   v-model="from"/>
      </b-col>
      <b-col md="6" cols="12">
        <DateGroup :label="$t('date_to')"
                   :required="true"
                   v-model="to"/>
      </b-col>
    </b-row>
    <InputGroup v-if="documentType === 'ndfl'"
                :label="$t('document_number')"
                v-model="docNumber"/>
    <div class="alert alert-danger" v-if="error">{{ error }}</div>
    <template #modal-footer="{ close }">
      <div class="modal-footer-buttons">
        <b-button class="cancel mr-3 d-inline-block align-middle"
                  variant="themed"
                  @click="close()">
          {{ $t('cancel') }}
        </b-button>
        <button :disabled="loading"
                @click="next"
                class="btn-themed align-middle btn-themed-squared">
          {{ $t('continue') }}
        </button>
      </div>
    </template>
  </b-modal>
</template>

<script>

import DateGroup from '@/components/form/DateGroup'
import RadioGroup from "@/components/form/RadioGroup"
import JwtService from "@/services/jwt.service"
import { mapState } from "vuex"
import { countries } from "@/dictionaries/dictionary"
import InputGroup from "@/components/form/InputGroup.vue"

export default {
  name: "PatientPrintModal",
  components: {
    InputGroup,
    DateGroup,
    RadioGroup
  },
  data() {
    return {
      error: null,
      loading: false,
      from: null,
      to: null,
      docNumber: null,
      documentType: 'ortodentic',
      allDocumentTypes: [
        { id: 'ortodentic', title: 'ortodentic_formula'},
        { id: 'ortodentic_ru', title: 'ortodentic_formula'},
        // { id: 'tooth_formula', title: 'tooth_formula'},
        { id: 'disease_history', title: 'disease_history'},
        { id: 'f43ukr', title: 'f43ukr', right: 'form_ukraine' },
        { id: 'f43rus', title: 'f43rus', right: 'form_russia'},
        // { id: 'f43kaz', title: 'f43kaz', right: 'form_kaz'},
        { id: 'f58kaz', title: 'f58kaz', right: 'form_kaz'},
        { id: 'f37u78', title: 'f37u78'},
        { id: 'akt_period', title: 'akt_of_services_for_period'},
        { id: 'ndfl_statement', title: 'ndfl_statement'},
        { id: 'ndfl', title: 'ndfl_statement_new'}
      ],
      // documentTypes: []
    }
  },
  methods: {
    next() {
      this.error = null
      if(this.documentType === 'akt' && (!this.from || !this.to)) {
        this.error = this.$t('dates_not_selected')
            return
      }
      let link = JwtService.getPrintPrelink(this.clinic)
      let url = link +'/document/'+this.patient.id+'/'+this.documentType+'?t=r&branchId='+this.branch
      if(this.from) { url += '&from='+this.from }
      if(this.to) { url += '&to='+this.to }
      if(this.docNumber && this.documentType === 'ndfl') { url += '&docId='+this.docNumber }
      window.open(url, '_blank')
    }
  },
  computed: {
    ...mapState({
      patient: state => state.patient.patient,
      clinic: state => state.auth.clinic,
      branch: state => state.auth.branch,
      rights: state => state.auth.rights,
    }),
    documentTypes() {
      const countryId = this.clinic.country_id
      const arr = this.allDocumentTypes.filter(item => {
        if(!item.right || this.rights.includes(item.right)) {
          if(countryId == countries.UKRAINE && item.id == 'ortodentic_ru') return;
          if(countryId != countries.UKRAINE && item.id == 'ortodentic') return;
          if(countryId != countries.UKRAINE && item.id == 'f43ukr') return;
          if(countryId != countries.KAZAKHSTAN && item.id == 'f58kaz') return;
          if(countryId != countries.RUSSIA && item.id == 'ndfl_statement') return;
          if(![countries.RUSSIA, countries.KYRGYZSTAN].includes(countryId) && item.id == 'f43rus') return;
          if(![countries.RUSSIA, countries.KYRGYZSTAN].includes(countryId) && item.id == 'f37u78') return;
          return item
        }
      })
      // if(this.clinic.id == 1843) {
      //   arr.push({ id: 'ndfl', title: 'ndfl_statement_new'},)
      // }
      return arr
    }
  },
  mounted() {
    // this.allDocumentTypes.forEach(item => {
    //   if(!item.right || this.rights.includes(item.right)) {
    //     if(this.clinic.country_id == countries.UKRAINE && item.id == 'ortodentic_ru') return;
    //     if(this.clinic.country_id != countries.UKRAINE && item.id == 'ortodentic') return;
    //     if(this.clinic.country_id != countries.RUSSIA && item.id == 'ndfl_statement') return;
    //     this.documentTypes.push(item)
    //   }
    // })
  },
  watch: {
    from() { this.error = null },
    to() { this.error = null },
    documentType() { this.error = null },
  }
}
</script>
