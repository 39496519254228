<template>
  <span class="data-table-additional-action-span">
  <a class="data-table-action"
     v-if="editable"
     :title="$t('edit')"
     v-b-tooltip.hover
     @click="edit">
    <EditSvg/>
    <span class="action-button-mob-title">{{ $t('edit') }}</span>
  </a>
  <a v-if="hasBtn"
     :title="$t(btnTitle)"
     v-b-tooltip.hover
     class="data-table-action"
     @click="nextStep">
    <PenSvg class="svg-light"/><span class="action-button-mob-title">{{ $t(btnTitle) }}</span>
  </a>
  <a :title="$t('delete')"
     v-if="deletable"
     v-b-tooltip.hover
     class="data-table-action"
     @click="deleteRec">
    <TrashSvg class="svg-light"/><span class="action-button-mob-title">{{ $t('delete') }}</span>
  </a>
    </span>
</template>

<script>
import EditSvg from '@/assets/svg-vue/general/edit.svg'
import PenSvg from '@/assets/svg-vue/form/tick.svg'
import TrashSvg from '@/assets/svg-vue/general/trash_tr.svg'
import { mapState } from "vuex"
import { CalendarService, EloquentService } from "@/services/api.service"

export default {
  name: "EGISZDatatableActions",
  components: {
    EditSvg,
    PenSvg,
    TrashSvg
  },
  props: {
    object: {
      type: Object
    }
  },
  computed: {
    ...mapState({
      rights: state => state.auth.rights,
      user: state => state.auth.user,
    }),
    hasBtn() {

      if(this.object.status === 'draft' && this.rights.includes('egisz_edit')) return true
      if(this.object.status === 'requires_attention') {
        if(this.rights.includes('egisz_sign_for_others')) return true
        if(this.rights.includes('egisz_sign_own') && this.object.record.doctor_id === this.user.id) return true
      }
      if(this.object.status === 'signed_by_doctor' && this.rights.includes('egisz_sign_final')) return true
      if(this.object.status === 'signed_by_main_doctor' && this.rights.includes('egisz_sign_final')) return true
      return false
    },
    btnTitle() {
      const arr = {
        'draft': 'to_sign',
        'requires_attention': 'sign',
        'signed_by_doctor': 'sign',
        'signed_by_main_doctor': 'send_to_egisz',
        'sent_to_egisz': '-',
      }
      return arr[this.object.status]
    },
    editable() {
      if(!this.rights.includes('egisz_edit')) return false
      return ['draft', 'requires_attention'].includes(this.object.status)
    },
    deletable() {
      if(!this.rights.includes('egisz_delete')) return false
      return ['draft', 'requires_attention', 'signed_by_doctor', 'signed_by_main_doctor'].includes(this.object.status)
    }
  },
  data() {
    return {
      selectedRecord: null,
    }
  },
  methods: {
    async deleteRec() {
      const answer = confirm(this.$t('are_you_sure_you_want_to_delete'))
      if(!answer) return
      await EloquentService.delete('egiszRecord', this.object.id)
      this.$store.commit('incrementDataTableCounter')
    },
    async nextStep() {
      if(['requires_attention','signed_by_doctor'].includes(this.object.status)) {
        try {
          const res = await CalendarService.validateEGISZ(this.object.id)
          if(res?.data?.id) {
            this.$store.commit('setEGISZSelectedRecordSign', this.copyObject(this.object))
            await this.$nextTick()
            this.$bvModal.show('sign-document-modal')
          }
        } catch (e) {
          this.$noty.error(e.response.data.message)
        }
        return
      }
      try {
        const res = await CalendarService.EGISZNextStep(this.object.id)
        if(res?.data?.id) {
          this.$store.commit('incrementDataTableCounter')
        }
      } catch (e) {
        this.$noty.error(e.response.data.message)
      }
    },
    async edit() {
      this.$store.commit('setEGISZSelectedRecordEdit', this.copyObject(this.object))
      await this.$nextTick()
      this.$bvModal.show('egisz-form-document-modal')
    }
  }
}
</script>
