<template>
  <b-modal id="transfer-between-deposit-accounts-modal"
           size="sm"
           @show="onShow"
           hide-footer
           :title="$t('transfer_between_patient_accounts')"
           centered>

    <div>
      <SelectGroup :label="$t('from_account')"
                   :showlabel="true"
                   title="title"
                   variant="white"
                   :options="sourceOptions"
                   v-model="sourceAccountId"/>

      <SelectGroup :label="$t('to_account')"
                   :showlabel="true"
                   title="title"
                   variant="white"
                   :options="destinationOptions"
                   v-model="destinationAccountId"/>

      <InputGroup type="number"
                  :label="$t('sum')"
                  :required="true"
                  v-model="amount"/>
      <div v-if="sourceMaxAmount">
        {{ $t('transfer_whole_amount') }}:
        <a class="text-underlined pointer" @click="amount = sourceMaxAmount">
          {{ formatCurrency(sourceMaxAmount) }}
        </a>
      </div>

      <div class="text-right mt-5">
        <button class="btn btn-themed btn-themed-squared w-100"
                :disabled="loading || !valid"
                @click="save">
          {{ $t('save') }}
        </button>
      </div>
    </div>
  </b-modal>
</template>

<script setup>
import SelectGroup from "@/components/form/SelectGroup.vue"
import { computed, getCurrentInstance, ref } from "vue"
import { useStore } from "vuex"
import { PatientService } from "@/services/api.service"
import InputGroup from "@/components/form/InputGroup.vue"
import { formatCurrency } from "@/extensions/filters/filters"

const store = useStore()
const instance = (getCurrentInstance())

const patient = computed(() => store.state.patient.patient)
const deposit_by_accounts = computed(() => patient.value.deposit_by_accounts)
const allDepositAccounts = computed(() => store.state.auth.deposit_accounts)
const branches = computed(() => store.state.auth.branches)
const branchId = computed(() => Number(store.state.auth.branch))

const sourceAccountId = ref(null)
const destinationAccountId = ref(null)
const amount = ref(null)

const loading = computed(() => store.state.dom.loading)

const sourceMaxAmount = computed(() => {
  if(!sourceAccountId.value) return 0
  return deposit_by_accounts.value.find((obj) => obj.deposit_account.id === Number(sourceAccountId.value))?.amount || 0
})

const valid = computed(() => {
  if(!sourceAccountId.value || !destinationAccountId.value || amount.value <= 0) return false
  if(sourceAccountId.value === destinationAccountId.value) return false
  return Number(amount.value) <= Number(sourceMaxAmount.value)
})

const accountOptions = computed(() => deposit_by_accounts.value
    .filter((obj) => Number(obj.branch_id) === branchId.value)
    .map((obj) => ({
      id: obj.deposit_account.id,
      title: `${obj.deposit_account.title}${getBranchTitle(obj)} | ${formatCurrency(obj.amount)}`
    })))

const allDepositAccountsWithAmount = computed(() => allDepositAccounts.value.map((account) => {
  const amount = deposit_by_accounts.value.find((obj) => Number(obj.deposit_account.id) === Number(account.id))?.amount || 0
  return {
    id: account.id,
    title: `${account.title} | ${formatCurrency(amount)}`
  }
}))

const sourceOptions = computed(() => accountOptions.value.filter((obj) => obj.id !== Number(destinationAccountId.value)))
const destinationOptions = computed(() => allDepositAccountsWithAmount.value.filter((obj) => obj.id !== Number(sourceAccountId.value)))

const getBranchTitle = (acc) => {
  if(branches.value.length <= 1) return ''
  const branch = branches.value.find((obj) => Number(obj.id) === Number(acc.branch_id))
  if(!branch) return ''
  return ` (${branch.title}) `
}

const save = async () => {
  try {
    await PatientService.transferBetweenDepositAccounts(patient.value.id, {
      sourceAccountId: sourceAccountId.value,
      destinationAccountId: destinationAccountId.value,
      amount: amount.value
    })
  } catch (e) {
    console.log(e)
    return
  }
  await store.dispatch('getPatient', patient.value.id)
  const bvModal = instance?.ctx?._bv__modal
  bvModal?.hide('transfer-between-deposit-accounts-modal')
  store.commit('incrementDataTableCounter')
}

const onShow = () => {
  sourceAccountId.value = null
  destinationAccountId.value = null
  amount.value = null
}
</script>
