<template>
  <div>
    <AddObject module="settings"  btnclass="btn-themed-outline btn-themed-squared mt-2"/>
    <DataTable :options="options"
               class="mt-4"
               module="settings"/>
  </div>
</template>

<script>

import DataTable from "@/components/parts/general/DataTable"
import options from "@/data/settings/client_source"
import AddObject from "@/components/parts/datatable/AddObject"
import {mapState} from "vuex"

export default {
  name: "SettingsSources",
  head() {
    return {
      title: this.$t('patient_sources'),
    }
  },
  components: {
    AddObject,
    DataTable
  },
  data() {
    return {
      options: options
    }
  },
  computed: {
    ...mapState({
      clinic: state => state.auth.clinic,
    }),
  },
  mounted() {
    this.$store.commit('setEloquentOptions', options)
  }
}
</script>
