<template>
  <span>
    <template v-if="object.status == 2">
        <span class="text-danger">{{ $t('cancelled') }}</span>
        <span class="text-danger" v-if="object.record_cancellation"> - {{ object.record_cancellation.reason }}</span>
    </template>
    <template v-else>
      <div>{{ $t(object.readableStatus) }}</div>
      <div v-if="object.price > 0">
        <span class="text-danger" v-if="object.is_paid === '0'">{{ $t('debt') }}</span>
        <span class="text-success" v-if="object.is_paid === '1'">{{ $t('paid') }}</span>
      </div>
    </template>

  </span>
</template>

<script>



export default {
  name: "PatientsDatatableTDRecordStatus",
  props: {
    object: {
      type: Object
    }
  },
  computed: {

  },
}
</script>

<style scoped>

</style>
