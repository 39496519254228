import Vue, { createApp } from 'vue'

import '@/extensions/prototypes/prototypes'
import '@/extensions/filters/filters'
import "@/extensions/firebase/firebase"

import store from '@/store'
import router from '@/router'
import App from '@/components/pages/general/App.vue'
import Default from '@/components/layouts/Default'
import SuperAdmin from '@/components/layouts/SuperAdmin'
import Empty from '@/components/layouts/Empty'
import { BootstrapVue } from 'bootstrap-vue'
import { VueHeadMixin, createHead } from '@unhead/vue'
import ApiService from "@/services/api.service"
import VueNoty from 'vuejs-noty'
import VueExpandableImage from 'vue-expandable-image'
import VueDOMPurifyHTML from 'vue-dompurify-html'
Vue.use(VueExpandableImage)
import "regenerator-runtime"

Vue.component('default-layout', Default)
Vue.component('super_admin-layout', SuperAdmin)
Vue.component('empty-layout', Empty)

const app = createApp(App)

app.use(VueNoty, {
  timeout: 3000,
  progressBar: false,
  layout: 'bottomRight'
})
app.use(BootstrapVue)
const head = createHead()
app.mixin(VueHeadMixin)
app.use(head)
app.use(VueDOMPurifyHTML)

import clickOutside from '@/extensions/directives/v-click-outside'
import spaceduckCollapse from '@/extensions/directives/spaceduck-collapse'
app.directive('click-outside', clickOutside)
app.directive('spaceduck-collapse', spaceduckCollapse)

import VueExcelXlsx from "vue-excel-xlsx"
app.use(VueExcelXlsx)

import VueHtml2Canvas from 'vue-html2canvas'
app.use(VueHtml2Canvas)

import * as Sentry from "@sentry/vue"
import { Integrations } from "@sentry/tracing"

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    Vue,
    dsn: "https://3884683fe4014215a8329b8b5e9612f9@o689484.ingest.sentry.io/5787285",
    integrations: [new Integrations.BrowserTracing()],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    // tracesSampleRate: 1.0,
    tracesSampleRate: 0.05,
  })
}

import i18n from '@/lang/i18n'
Vue.config.productionTip = false

import VueYandexMetrika from 'vue-yandex-metrika'
Vue.use(VueYandexMetrika, {
  id: 82347988,
  router: router,
  env: process.env.NODE_ENV,
  options: {
    clickmap:true,
    trackLinks:true,
    accurateTrackBounce:true,
    webvisor:true
  }
})

ApiService.init(i18n, router, app)

window.localStorage.setItem('lastUpdate', Date.now())

// window.addEventListener("flutterInAppWebViewPlatformReady", function() {
//   alert('main')
// });

const init = () => {
  app.use(i18n).use(router).use(store).mount('#app')
}

window.addEventListener("flutterInAppWebViewPlatformReady", function() {
  store.commit('setFlutterApp', true)
})

if(store.state.auth.isLogged) {
  store.dispatch('init').then(() => {
    init()
  }).catch(() => {
    // store.dispatch('logout')
  })
} else {
  init()
}


