<template>
  <div>
    <div class="template-group">
      <div v-for="(s, sind) in servicesArray"
           :key="`service-tr-${s.id}`"
           class="data-table-wrapper"
           :class="{'mb-4': full }">
        <table class="table-blue services-table">
          <thead v-if="full || sind === 0">
          <tr>
            <th>{{ $t('code') }}</th>
            <th>{{ $t('service') }}</th>
            <th>{{ $t('cost') }}</th>
            <th>{{ $t('amount') }}</th>
            <th>{{ $t('discount') }}</th>
            <th>{{ $t('sum_with_discount') }}</th>
            <th></th>
          </tr>
          </thead>
          <tbody>
          <tr class="position-relative" :key="`tr-service-${sind}-${versionCount}`">
            <td :data-title="$t('code')" class="td-code">
              {{ s.cod }}
            </td>
            <td :data-title="$t('service')" class="order-mob-0 service-name-td">
              {{ s.name }}
              <div v-if="s.price" class="text-muted">
                {{ $t('price_price') }}: {{ s.price.title }}
              </div>
            </td>
            <td class="visit-service-cost-td" :data-title="$t('cost')">
              <input v-if="s.price_can_be_changed"
                     :readonly="fullReadonly"
                     @change="$emit('versionChange'); emitServices()"
                     min="0"
                     @keyup="$event.target.value < 0 ? s.pivot.price_original = 0 : ''"
                     class="form-control sm d-inline-block align-middle"
                     v-model="s.pivot.price_original"
                     type="number"/>
              <span v-else>{{ formatNumber(s.pivot.price_original) }}</span>
            </td>
            <td :data-title="$t('amount')" class="mob-25-td visible-expanded">
              <div class="position-relative">
                <div class="d-flex flex-row">
                  <input type="number"
                         @click="toggleAmountDropdown(s.id)"
                         class="form-control d-inline-block no-right-radius sm align-middle"
                         :readonly="true"
                         style="width: 50px"
                         :value="s.teeth.length"/>
                  <div class="pointer d-inline-flex align-middle flex-column justify-content-center plus-minus-block down-block px-2"
                       @click="toggleAmountDropdown(s.id)"
                       :class="{ disabled: fullReadonly }">
                    <ArrowDown2Svg/>
                  </div>
                </div>

                <div v-if="teeth.length > 0"
                     v-click-outside="clickedOutsideDropdown"
                     class="manual-dropdown dropdown-menu dropdown-menu-left"
                     :class="{ show: dropdownServiceIdOpened === s.id }">
                  <a class="dropdown-item font-weight-bolder">{{ $t('set_the_amount') }}</a>
                  <a class="dropdown-item" v-for="t in removeDuplicates(teeth)"
                     :key="`tooth-am-${sind}-${t}`">
                    <div class="d-inline-block" style="width: 30px;">{{ t }}:</div>
                    <div class="d-inline-block">
                      <div class="position-relative d-flex">
                        <input type="number"
                               :readonly="true"
                               :value="s.teeth.filter(x => x == t).length"
                               style="width: 50px"
                               class="form-control d-inline-block no-right-radius sm align-middle"/>
                        <div class="d-inline-flex align-middle flex-column plus-minus-block" :class="{ disabled: fullReadonly }">
                          <a class="plus"
                             :class="{ disabled: loading }"
                             tabindex="#"
                             @click="loading ? null : quantityChangedManually(s.id, 'plus', t)">+</a>
                          <a class="minus"
                             :class="{ disabled: loading }"
                             tabindex="#"
                             @click="loading ? null : quantityChangedManually(s.id, 'minus', t)">-</a>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>


                <!--                                <b-dropdown variant="link"-->
                <!--                                            right-->
                <!--                                            ref="dropdown"-->
                <!--                                            :disabled="teeth.length === 0"-->
                <!--                                            text="Dropdown Button">-->
                <!--                                  <template #button-content>-->
                <!--                                    <input type="number"-->
                <!--                                           class="form-control d-inline-block no-right-radius sm align-middle"-->
                <!--                                           :readonly="true"-->
                <!--                                           style="width: 50px"-->
                <!--                                           :value="s.teeth.length"/>-->
                <!--                                    <div class="d-inline-flex align-middle flex-column justify-content-center plus-minus-block down-block px-2" -->
                <!--                                         :class="{ disabled: fullReadonly }">-->
                <!--                                      <ArrowDown2Svg/>-->
                <!--                                    </div>-->
                <!--                                  </template>-->
                <!--                                  <a class="dropdown-item font-weight-bolder">{{ $t('set_the_amount') }}</a>-->
                <!--                                  <a class="dropdown-item" v-for="t in removeDuplicates(teeth)" -->
                <!--                                                   :key="`tooth-am-${sind}-${t}`">-->
                <!--                                    <div class="d-inline-block" style="width: 30px;">{{ t }}:</div>-->
                <!--                                    <div class="d-inline-block">-->
                <!--                                      <div class="position-relative d-flex">-->
                <!--                                        <input type="number"-->
                <!--                                               :readonly="true"-->
                <!--                                               :value="s.teeth.filter(x => x == t).length"-->
                <!--                                               style="width: 50px"-->
                <!--                                               class="form-control d-inline-block no-right-radius sm align-middle"/>-->
                <!--                                        <div class="d-inline-flex align-middle flex-column plus-minus-block" :class="{ disabled: fullReadonly }">-->
                <!--                                          <a class="plus" tabindex="#" @click="quantityChangedManually(s.id, 'plus', t)">+</a>-->
                <!--                                          <a class="minus" tabindex="#" @click="quantityChangedManually(s.id, 'minus', t)">-</a>-->
                <!--                                        </div>-->
                <!--                                      </div>-->
                <!--                                    </div>-->
                <!--                                  </a>-->
                <!--                                </b-dropdown>-->
              </div>

            </td>
            <td :data-title="$t('discount')" class="mob-75-td">
              <input type="number"
                     v-model="s.pivot.discount"
                     :readonly="fullReadonly || !rights.includes('edit_discounts')"
                     @change="$emit('versionChange'); emitServices()"
                     @keyup="(($event.target.value > 100 && s.pivot.discount_type === 'percent') ? s.pivot.discount = 100 : $event.target.value < 0 ? s.pivot.discount = 0 : '')"
                     :max="s.pivot.discount_type === 'percent' ? 100 : s.pivot.price_original"
                     min="0"
                     class="form-control sm d-inline-block align-middle"/>
              <select style="width: 60px"
                      class="form-control sm d-inline-block align-middle ml-2"
                      @change="typeOfDiscountChanged(s)"
                      :disabled="fullReadonly || !rights.includes('edit_discounts')"
                      v-model="s.pivot.discount_type">
                <option value="percent">%</option>
                <option value="absolute">{{ $store.getters.getCurrencyName }}</option>
              </select>
            </td>
            <td :data-title="$t('sum_with_discount')">
              {{ formatNumber(((getServiceCost(s) - (s.pivot.discount_type === 'percent' ? (getServiceCost(s) * (s.pivot.discount / 100)) : (s.pivot.discount / s.teeth.length)))) * s.teeth.length) }}
            </td>
            <td class="diagnosis-delete-td text-right">
              <a v-if="full && !fullReadonly" class="pointer" @click="deleteService(s.id)"><TrashSvg/></a>
              <a v-if="!full" class="more-info" v-spaceduck-collapse="`service-collapse-${sind}`"><ArrowSvg/></a>
            </td>
            <MobileMoreArrow/>
          </tr>
          </tbody>
        </table>
        <div>
          <div :class="{ 'pb-4': !full }">
            <div class="code-offset mt-3 position-relative">
              <b-row class="row-narrow align-items-center">
                <b-col cols="auto">
                  <ToothSvg/>
                </b-col>
                <b-col>
                  <div v-for="(t, tind) in teeth"
                       :key="`s-t-${s.id}-${t}`"
                       :class="{ 'ml-2': tind > 0 }"
                       class="d-inline-block">
<!--                    <input type="checkbox"-->
<!--                           :id="`st-${s.id}-${t}`"-->
<!--                           :value="t"-->
<!--                           :disabled="fullReadonly"-->
<!--                           @change="(...args) => toothCheckboxPressed(s.id, t, ...args)"-->
<!--                           v-model="services[s.id].teeth"/>-->
                    <input type="checkbox"
                           :id="`st-${s.id}-${t}`"
                           :value="t"
                           :disabled="fullReadonly"
                           @change="(...args) => toothCheckboxPressed(s.id, t, ...args)"
                           :checked="services[s.id].teeth.includes(t)"/>
                    <label class="ml-1 mb-0" :for="`st-${s.id}-${t}`">
                      {{ filterTeeth(t) }} ({{ services[s.id].teeth.filter(x => x == t).length }})
                    </label>
                  </div>
                </b-col>
              </b-row>
              <a v-if="full"
                 class="full-arrow more-info position-absolute"
                 v-spaceduck-collapse="`service-collapse-${sind}`">
                <ArrowDownSvg/>
              </a>
            </div>

            <spaceduck-collapse class="code-offset" :id="`service-collapse-${sind}`">
              <b-row class="align-items-center mt-3">
                <b-col md="6" cols="12">
                  <b-row class="row-narrow align-items-center">
                    <b-col cols="auto">
                      <DoctorSvg/>
                    </b-col>
                    <b-col>
                      <select v-model="s.pivot.doctor_id"
                              class="form-control mw-auto sm"
                              @input="delayedChangeServices"
                              :disabled="fullReadonly">
                        <option v-for="doctor in doctors"
                                :key="`option-doc-${doctor.id}`"
                                :value="doctor.id">
                          {{ doctor.full_name }}
                        </option>
                      </select>
                    </b-col>
                  </b-row>
                </b-col>
                <b-col md="6" cols="12"  v-if="s.technics.length > 0">
                  <b-row class="row-narrow align-items-center mt-md-0 mt-3">
                    <b-col cols="auto">
                      <TechnicSvg/>
                    </b-col>
                    <b-col>
                      <select v-model="s.pivot.technic_id"
                              class="form-control mw-auto sm"
                              :disabled="fullReadonly"
                              @input="delayedChangeServices">
                        <option v-for="technic in s.technics"
                                :key="`option-t-${technic.id}`"
                                :value="technic.id">
                          {{ technic.full_name }}
                        </option>
                      </select>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
              <VisitPackagesAndMaterials v-if="full"
                                         :ref="`packagesAndMaterials-${s.id}`"
                                         class="mt-4"
                                         @versionChange="$emit('versionChange')"
                                         :serviceid="s.id"
                                         :readonly="fullReadonly"/>
            </spaceduck-collapse>
          </div>
        </div>
      </div>
    </div>
    <div v-if="canAddService && teeth.length > 0 && Object.keys(services).length > 0">
      <hr/>
    </div>
    <div class="template-group"
         :key="`cost-total-${versionCount}`"
         v-if="canAddService">
      <div v-if=" teeth.length > 0">
        <div v-if="Object.keys(services).length > 0 && full">
          <div class="text-uppercase">{{ $t('total') }}: {{ formatCurrency(costTotal) }}</div>
          <div class="">{{ $t('discount') }}: {{ formatCurrency(costTotal - costTotalWithDiscount) }}</div>
          <div class="">{{ $t('additional_discount') }}: {{ formatCurrency(additionalDiscount) }}</div>
          <div class="text-uppercase font-weight-bold">{{ $t('total_with_discount') }}: {{ formatCurrency(costTotalWithDiscount - additionalDiscount) }}</div>
        </div>
        <div :class="{ 'mt-4': Object.keys(services).length > 0 }"
             v-if="teeth.length > 0 && !fullReadonly">
          <label class="form-label">{{ $t('add_service') }}</label>
          <Autocomplete :placeholder="$t('search')+'...'"
                        model="service"
                        class="template-like"
                        :autocomplete-additional-params="{ insurance_company_id: visit && visit.insurance_polis ? visit.insurance_polis.insurance_company_id : null }"
                        @input="newServiceFound"/>
        </div>
      </div>
      <div v-if="teeth.length < 1 && full">{{ $t('to_add_services_you_need_to_select_teeth') }}</div>
    </div>
  </div>
</template>

<script>

import ToothSvg from '@/assets/svg-vue/patients/tooth.svg'
import DoctorSvg from '@/assets/svg-vue/patients/doctor.svg'
import TechnicSvg from '@/assets/svg-vue/patients/technic.svg'
import TrashSvg from "@/assets/svg-vue/general/trash.svg"
import ArrowSvg from '@/assets/svg-vue/warehouse/circlearrow.svg'
import ArrowDownSvg from '@/assets/svg-vue/general/arrow_down.svg'
import ArrowDown2Svg from '@/assets/svg-vue/patients/arrow_down.svg'
import Autocomplete from "@/components/parts/general/Autocomplete"
import { mapState } from "vuex"
import MobileMoreArrow from "@/components/parts/datatable/MobileMoreArrow"
import VisitPackagesAndMaterials from "@/components/parts/patients/visit/VisitPackagesAndMaterials"
import { formatNumber, formatCurrency, filterTeeth } from "@/extensions/filters/filters"
import SpaceduckCollapse from "@/components/parts/general/SpaceduckCollapse.vue"

export default {
  name: "VisitServices",
  components: {
    SpaceduckCollapse,
    VisitPackagesAndMaterials,
    MobileMoreArrow,
    // ServiceMultiSelect,
    ToothSvg,
    DoctorSvg,
    TrashSvg,
    TechnicSvg,
    ArrowSvg,
    ArrowDownSvg,
    ArrowDown2Svg,
    Autocomplete,
  },
  computed: {
    ...mapState({
      teeth: state => state.teeth.recordTeeth,
      doctors: state => state.auth.doctors,
      patient: state => state.patient.patient,
      units: state => state.auth.units,
      rights: state => state.auth.rights,
      services: state => state.treatment.services,
      materials: state => state.treatment.materials,
      packages: state => state.treatment.packages,
      loading: state => state.dom.loading,
      dropdownServiceIdOpened: state => state.treatment.dropdownServiceIdOpened,
    }),
    full() {
      return ['visit','plan'].includes(this.view)
    },
    canAddService() {
      return ['visit','plan','payment'].includes(this.view)
    },
    servicesArray() {
      let arr = []
      for (var i in this.services) {
        arr.push(this.services[i])
      }
      arr.sort(function(a, b) {
        if(a.order < b.order) return -1
        if(a.order > b.order) return 1
        return 0
      })
      return arr
    },
    fullReadonly() {
      return this.readonly || (this.hasInitialServices && !this.rights.includes('edit_unpaid_services'))
    },
    costTotal() {
      return Object.values(this.services).reduce((t, item) => {
        return t + parseFloat((this.getServiceCost(item)) * item.teeth.length)
      }, 0) + this.versionCount * 0 // this hack is to force reactivity, do not delete
    },
    costTotalWithDiscount() {
      return Object.values(this.services).reduce((t, item) => {
        let pivot = item.pivot
        let price = this.getServiceCost(item)
        return t + (price - (pivot.discount_type === 'percent' ? (price * (Math.min(pivot.discount, 100) / 100)) : (pivot.discount / item.teeth.length))) * item.teeth.length
      }, 0) + this.versionCount * 0 // this hack is to force reactivity, do not delete
    },
    additionalDiscount() {
      return Number(this.visit?.additional_discount || 0)
    },
    costTotalWithDiscountComputed() {
      return this.costTotalWithDiscount
    }
  },
  props: {
    visit: {
      type: Object
    },
    view: {
      type: String,
      default: "visit"
    },
    readonly: {
      type: Boolean,
      default: false
    },
    versionCount: {
      type: Number
    }
  },
  data() {
    return {
      prevPackages: {},
      hasInitialServices: false,
      justOpened: false,
    }
  },
  methods: {
    formatNumber,
    formatCurrency,
    filterTeeth,
    parseServices() {
      let arr = {}
      let visit_teeth = this.view === 'plan' ? 'teeth' : 'record_teeth'
      let tooth_teeth = this.view === 'plan' ? 'tooth' : 'teeth'
      if(!this.visit || !this.visit[visit_teeth]) {
        // this.services = arr
        this.$store.commit('setServices', this.copyObject(arr))
        return
      }
      this.visit[visit_teeth].forEach(tooth => {
        tooth.services.forEach(service => {
          if(!arr[service.id]) {
            service.teeth = [tooth[tooth_teeth]]
            service.pivot.amount = 1
            if(!service.pivot.discount_type) {
              service.pivot.discount_type = 'percent'
            }
            service.pivot.discount = service.pivot.discount === null ? 0 : parseFloat(service.pivot.discount)
            service.order = Object.keys(arr).length + 1
            service.pivot.discount_absolute = parseFloat(service.pivot.discount_absolute)
            arr[service.id] = service
            this.hasInitialServices = true
          } else {
            if(arr[service.id].teeth.includes(tooth[tooth_teeth])) {
              arr[service.id].pivot.amount += 1
            }
            arr[service.id].pivot.discount_absolute += parseFloat(service.pivot.discount_absolute)
            arr[service.id].teeth.push(tooth[tooth_teeth])
            this.hasInitialServices = true
          }
        })
      })
      this.$store.commit('setServices', this.copyObject(arr))
      for(let service_id in this.services) {
        let s = this.services[service_id]
        if(s.pivot.discount_type === 'absolute') {
          this.typeOfDiscountChanged(s, false, s.pivot.discount_absolute)
        }
      }
      this.emitServices()
    },
    async newServiceFound(service, teeth = null) {
      await this.$store.dispatch('newServiceFound', {
        visit: this.visit,
        teeth: teeth || this.copyObject(this.teeth),
        patient: this.patient,
        service,
        selectedTooth: null
      })
      this.$emit('versionChange')
    },
    addMaterialToService(service_id, materials) {
      this.$store.dispatch('addMaterialToService', { service_id, materials })
    },
    deleteService(id) {
      this.$store.commit('deleteService', id)
      this.$emit('versionChange')
    },
    removeDuplicates(arr) {
      return [ ...new Set(arr) ]
    },
    getServiceCost(item) {
      return item.pivot.price_original
      // return this.view === 'plan' && this.versionCount > 0 ? parseFloat(item.cost_branch) : item.pivot.price_original
    },
    async quantityChangedManually(service_id, sign = 'plus', tooth = null) {
      if(this.fullReadonly) return
      if(sign === 'minus' && this.services[service_id].teeth.filter(t => t === tooth).length === 0) return
      this.justOpened = true
      setTimeout(() => { this.justOpened = false },200)
      await this.$store.dispatch('quantityChangedManually', {
        service_id,
        sign,
        teeth: this.copyObject(this.services[service_id].teeth),
        new_tooth: tooth,
        qty: 1
      })
      setTimeout(() => {
        this.emitServices()
        this.$emit('versionChange')
      }, 1)
    },
    emitServices() {
      this.$store.commit('setServices', this.copyObject(this.services))
      this.$emit('costTotal', this.costTotal, this.costTotalWithDiscount)
    },
    typeOfDiscountChanged(s, changeVersion = true, absAmountPassed = null) {
      let servicePrice = s.pivot.price_original
      let discount_type = s.pivot.discount_type
      let previousAmount = s.pivot.discount
      let newAmount = 0
      if(discount_type === 'absolute') {
        newAmount = absAmountPassed ? parseFloat(absAmountPassed) : Math.round(servicePrice * s.teeth.length * previousAmount * 100) / 10000
      }
      if(discount_type === 'percent') {
        newAmount = (previousAmount / (servicePrice * s.teeth.length)) * 100
      }
      s.pivot.discount = newAmount
      if(changeVersion) this.$emit('versionChange')
    },
    async toothCheckboxPressed(service_id, tooth, e) {
      let sign = e.target.checked ? 'plus' : 'minus'
      let count = sign == 'plus' ? 1 : (this.services[service_id].teeth.filter(t => t == tooth)).length
      for (let i = 0; i < count; i++) {
        await this.$store.dispatch('quantityChangedManually', {
          service_id,
          sign,
          teeth: this.copyObject(this.services[service_id].teeth),
          new_tooth: tooth,
          qty: 1
        })
      }
      this.$emit('versionChange')
      this.emitServices()
    },
    toggleAmountDropdown(service_id) {
      this.$store.commit('setDropdownServiceIdOpened', this.dropdownServiceIdOpened === service_id ? null : service_id)
      this.justOpened = true
      setTimeout(() => { this.justOpened = false },200)
    },
    clickedOutsideDropdown() {
      if(!this.justOpened) this.$store.commit('setDropdownServiceIdOpened', null)
    },
    delayedChangeServices() {
      setTimeout(() => {
        this.$emit('versionChange')
      }, 50)
    },
    addedMaterial({ serviceId, material}) {
      this.$refs[`packagesAndMaterials-${serviceId}`][0].newMaterialFound(material)
    },
    removedMaterial({ serviceId, material}) {
      this.$refs[`packagesAndMaterials-${serviceId}`][0].removedMaterial(material)
    },
    materialAmountChanged({ serviceId, material}) {
      this.$refs[`packagesAndMaterials-${serviceId}`][0].materialAmountChanged(material)
    },
    addedPackage({ serviceId, kit}) {
      this.$refs[`packagesAndMaterials-${serviceId}`][0].addedPackage(kit, [], true)
    },
    removedPackage({ serviceId, kit}) {
      this.$refs[`packagesAndMaterials-${serviceId}`][0].removedPackage(kit, true)
    }
  },
  mounted() {
    this.parseServices()
    this.$store.commit('setMaterials', this.copyObject(this.visit.materials && !Array.isArray(this.visit.materials) ? this.visit.materials : {}))
    this.$store.commit('setPackages', this.copyObject(this.visit.packages && !Array.isArray(this.visit.packages) ? this.visit.packages : {}))
    this.prevPackages = this.copyObject(this.packages)

  },
  watch: {
    costTotalWithDiscountComputed() {
      this.$emit('costTotal', this.costTotal, this.costTotalWithDiscount)
    },
  }
}
</script>

<style scoped lang="scss">
.full-arrow {
  right: 4px;
  top: 0;
  padding: 0 8px;
}
.down-block {
  background: $gray-icon;
}
.manual-dropdown {
  display: none;
  &.show {
    display: block;
  }
}
@media screen and (min-width: 768px) {
  .visit-service-cost-td {
    width: 100px;
  }
  .mob-25-td {
    width: 90px;
  }
  .mob-75-td {
    width: 200px;
  }
}
@media screen and (max-width: 768px) {
  .full-arrow {
    right: -2px;
  }
}
.service-name-td {
  width: 35%;
}
</style>
